import { openNotification } from "./Notification";

// сообщения от бэка приходят без точки, сообщения из джанги - с ней, регулярка всё подгоняет под одну гребёнку
const dotAnnihilator = (sentence: string) => {
  return sentence?.replace(/\.$/gm, '');
}

export const errorNotification = (error?: {status?: number, data?: { detail: string}} | null, message: string | null = null) => {
  if (!error && message) {
    openNotification({
      title: 'Ошибка',
      description: `${message}`,
      type: 'error'
    })
  } else if (!error) {
    openNotification({
      title: 'Ошибка',
      description: `Неопределённая ошибка`,
      type: 'error'
    })
  } else if (error && message) {
    openNotification({
      title: 'Ошибка',
      description: `${dotAnnihilator(message)}. (статус: ${error.status})`,
      type: 'error'
    })
  } else if (error?.status) {
    if (error?.status === 400) {
      openNotification({
        title: 'Ошибка',
        description: error?.data?.detail ? `${dotAnnihilator(error?.data?.detail)}.` : error.data?.detail,
        type: 'error'
      })
    } else if ( error?.status > 400 && error?.status < 500 && error?.data?.detail) {
      openNotification({
        title: 'Ошибка',
        description: `Что-то пошло не так!
          ${dotAnnihilator(error?.data?.detail)}. (статус: ${error?.status})`,
        type: 'error'
      })
    } else if ( error?.status >= 500) {
      openNotification({
        title: 'Ошибка',
        description: `Ошибка доступа к серверу, повторите попытку позже или обратитесь в тех. поддержку. (статус: ${error.status})`,
        type: 'error'
      })
    }
  }
  else {
    openNotification({
      title: 'Ошибка',
      description: `Что-то пошло не так!
          ${dotAnnihilator(error?.data?.detail ?? '')}. (статус: ${error?.status})`,
      type: 'error'
    })
  }
}

export const successNotification = (response: {status?: number, data?: any} | string, message: string | null = null) => {
  if (typeof response === 'object') {
    if (response?.status === 200 && !response?.data) {
      openNotification({
        title: 'Операция выполнена.',
        description: 'Операция выполнена успешно!',
        type: 'success'
      })
    } else if (response?.status === 200 && response?.data) {
      openNotification({
        title: 'Операция выполнена.',
        description: `${dotAnnihilator(response?.data)}.`,
        type: 'success'
      })
    }
  } else if ( response ) {
    openNotification({
      title: 'Операция выполнена.',
      description: `${dotAnnihilator(response)}.`,
      type: 'success'
    })
  } else if (message) {
    openNotification({
      title: 'Операция выполнена.',
      description: `${dotAnnihilator(message)}.`,
      type: 'success'
    })
  } else {
    openNotification({
      title: 'Операция выполнена.',
      description: 'Операция выполнена успешно!',
      type: 'success'
    })
  }
}