import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { TableRegistrator } from './components/TableRegistrator/TableRegistrator';
import { ModalBunkBooking } from './components/ModalBunkBooking/ModalBunkBooking';
import { covidApi } from '../../api/covidApi/covidApi';
import { Layout } from 'antd';
import { Button } from '../../Components/Button/Button';
import { Department } from './model/Department.model';
import { ModalDeleteBunk } from './components/ModalDeleteBunk/ModalDeleteBunk';
import { Routes } from '../../routes/routes';
import { BookingList } from '../BookingList/BookingList';
import useInterval from '@use-it/interval';
import { ReservedBunkCount } from '../../api/covidApi/model/ReservedBunkCount.model';


const { Footer, Content } = Layout;


const Hospital = React.memo(() => {
    const [modalBookingVisible, setModalBookingVisible] = useState<boolean>(false);
    const [modalRemoveBookingVisible, setModalRemoveBookingVisible] = useState<boolean>(false);
    const [reservations, setReservations] = useState<ReservedBunkCount>();
    const [departments, setDepartments] = useState<Department[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<Department | undefined>();

    const history = useHistory();

    const uploadDepartments = useCallback(async () => {
      await covidApi.getDepartments().then((result) => {
        // @ts-ignore
        setDepartments(result);
      });
    }, []);

    const cleanupSelectedDepartmentOnModalClose = useCallback(() => {
      setSelectedDepartment(undefined);
    }, []);

    const uploadReservations = useCallback(async () => {
      await covidApi.getBookInDepartmentsCount().then((result) => {
        if (result) {
          setReservations(result);
        } else {
          setReservations(undefined);
        }

      });
    }, []);

    useEffect(() => {
      uploadDepartments();
      uploadReservations();
    }, [uploadDepartments, uploadReservations]);

    useInterval(() => {
      uploadDepartments();
      uploadReservations();
    }, 10000);

    const goToBookingList = () => {
      history.push(Routes.ROUTE_APP + Routes.ROUTE_HOSPITAL + Routes.ROUTE_BOOKING_LIST);
    };

    return (
      <Switch>
        <Route exact path={Routes.ROUTE_APP + Routes.ROUTE_HOSPITAL + Routes.ROUTE_BOOKING_LIST}
        >
          <BookingList backUrl={Routes.ROUTE_APP + Routes.ROUTE_HOSPITAL} />
        </Route>
        <>
          <Content className="tableContent">
            <TableRegistrator departments={departments} reservations={reservations} />
          </Content>
          <Footer className="tableFooter">
            <Button className="footerButton" onClick={() => setModalBookingVisible(true)}>Занять</Button>
            <Button className="footerButton" onClick={() => setModalRemoveBookingVisible(true)}>Выписать</Button>
            <Button className="footerButton" onClick={goToBookingList}>Активные брони</Button>
          </Footer>

          {modalBookingVisible &&
          <ModalBunkBooking
            uploadDepartments = {uploadDepartments}
            uploadReservations = {uploadReservations}
            departments={departments}
            setIsModalVisible={setModalBookingVisible}
            selectedDepartmentId={selectedDepartment?.id}
            cleanupSelectedHospitalOnModalClose={cleanupSelectedDepartmentOnModalClose}
          />
          }
          {modalRemoveBookingVisible &&
          <ModalDeleteBunk
            uploadDepartments = {uploadDepartments}
            uploadReservations = {uploadReservations}
            departments={departments}
            setIsModalVisible={setModalRemoveBookingVisible}
          />
          }
        </>
      </Switch>
    );
  }
);

export default Hospital;