import axios from 'axios';
import { doAuthCleanup } from "../services/doAuthCleanup";

export const axiosSecured = axios.create({
  baseURL: process.env.REACT_APP_URL ?? '',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosSecured.interceptors.request.use(
  (config: any) => {
    if (localStorage.getItem('access') !== 'undefined') {
      config.headers.Authorization = `Bearer ${localStorage.getItem('access')}`;
      return config;
    } else {
      doAuthCleanup();
      return Promise.reject('unauthorised');
    }
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

const successHandler = (response: any) => {
  return response;
};

const errorHandler = (error: any) => {
  if (error?.response?.status === 401) {
    axiosSecured
      .post(
        '/users/token/refresh/',
        {
          refresh: localStorage.getItem('refresh'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      .then((response: { data: { access: string } }) => {
        localStorage.setItem('access', response.data.access);
        error.config.Authorization = `Bearer ${localStorage.getItem('access')}`;
        securedAxiosInstance(error);
      })
      .catch((error: any) => {
        doAuthCleanup();
        // @ts-ignore
        sessionStorage.setItem('redirect_to', window.location);
        // @ts-ignore
        window.location = '/guest';
        return Promise.reject(error);
      });
  }
  else {
    return Promise.reject(error)
  }
};

axiosSecured.interceptors.response.use(
  (response: any) => successHandler(response),
  (error: any) => errorHandler(error)
);

const securedAxiosInstance = axiosSecured;

export default securedAxiosInstance;