import React, { useEffect } from 'react';
import { Col, Typography, Tooltip } from 'antd';
import { BankOutlined, FallOutlined, PhoneOutlined, RiseOutlined } from '@ant-design/icons';
import ChartService from '../../services/ChartService';
import { Hospital } from '../../api/covidApi/model/Hospital.model';
import styles from './styles/DashboardCircleItem.module.css';

export type DashboardItemProps = {
  data: Hospital;
};

const wrapperStyle: any = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#efeded',
  height: '100%',
  padding: '16px',
  borderRadius: '12px'
};

const DashboardItem: React.FC<DashboardItemProps> = ({ data }) => {
  useEffect(() => {
    let service: any;
    if (data) {
      service = new ChartService(data);
      service?.render();
    }
    return () => {
      service?.clear();
    };
  }, [data]);

  return (
    <Col span={6}>
      <div style={wrapperStyle}>
        <div
          style={{
            position: 'relative',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: 8
          }}
        >
          <Typography.Title level={5} style={{ textAlign: 'center' }}>
            {data.name}
          </Typography.Title>
          <Tooltip title="Адресс" placement="left">
            <div>
              <BankOutlined />
              <Typography.Text style={{ marginLeft: 8 }}>{data.address}</Typography.Text>
            </div>
          </Tooltip>
          <Tooltip title="Телефон" placement="left">
            <div>
              <PhoneOutlined />
              <Typography.Text style={{ marginLeft: 8 }}>{data.phone}</Typography.Text>
            </div>
          </Tooltip>
          <Tooltip title="Динамика" placement="rightTop">
            <div className={styles.dynamicStatisticElement}>
              {data.dynamic && data.dynamic?.total === 0
                ? null
                : data.dynamic && data.dynamic?.total >= 0
                  ? <RiseOutlined className={styles.riseArrow} />
                  : <FallOutlined className={styles.fallArrow} />}
              <Typography.Text style={{ marginLeft: 8 }}>{data.dynamic?.total} %</Typography.Text>
            </div>
          </Tooltip>
        </div>
        <div id={`dashboard_item_${data.id}`} style={{ minHeight: '320px' }} />
      </div>
    </Col>
  );
};

export default DashboardItem;
