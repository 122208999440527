import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Radio, Select } from 'antd';
// @ts-ignore
import styles from './styles/ModalTransferDepartament.module.css';
import { covidApi } from '../../../../api/covidApi/covidApi';
import { ModalTransferDepartmentProps } from './model/ModalTransferDepartment.model';
import { PatientInfoLocalState } from './model/PatientInfoLocalState';
import { DepartmentInfo } from '../../../../api/covidApi/model/DepartmentInfo.model';
import { RadioChangeEvent } from 'antd/es/radio';
import { areAllKeysDefined } from '../../../../services/areAllKeysDefined';

const { Option } = Select;

export const ModalTransferDepartment = React.memo(({ setShowTransfer, department, allDepartments, fetchDepartment }: ModalTransferDepartmentProps) => {

  const [selectedDepartmentData, setSelectedDepartmentData] = useState<DepartmentInfo>();

  const [patientInfo, setPatientInfo] = useState<PatientInfoLocalState>({
    sex: undefined,
    has_oxygen: undefined,
    selectedDepartment_id: undefined,
    will_has_oxygen: undefined,
    what_gender_will_replaced: undefined
  });

  const handleCloseModal = useCallback(() => {
    setShowTransfer(false);
  }, [setShowTransfer]);

  const handleSelectDepartment = useCallback((selectedDepartment) => {
    const selectedDepartmentData = allDepartments.find(departmentData => departmentData.name === selectedDepartment);
    if (selectedDepartmentData) {
      setPatientInfo(prevState => ({
        ...prevState,
        selectedDepartment_id: selectedDepartmentData.id
      }));
      const departmentsData = allDepartments.find(departmentData => departmentData.id === selectedDepartmentData.id);
      if (departmentsData) {
        setSelectedDepartmentData(departmentsData);
      }
    }
  }, [allDepartments]);

  const onClickTransferPatient = useCallback(() => {
    if (  typeof patientInfo.will_has_oxygen  === 'boolean' && patientInfo.what_gender_will_replaced && patientInfo.selectedDepartment_id
      && typeof patientInfo.has_oxygen  === 'boolean' && patientInfo.sex) {
      covidApi.transferPatient({
        to_has_oxygen: patientInfo.will_has_oxygen,
        to_sex: patientInfo.what_gender_will_replaced,
        to_department_id: patientInfo.selectedDepartment_id,
        from_has_oxygen: patientInfo.has_oxygen,
        from_sex: patientInfo.sex,
        from_department_id: department.id,
        count: 1
      })
        .then(response => {
            if (response) {
              fetchDepartment()
              setShowTransfer(false);
            }
          }
        );
    }
  }, [department.id, fetchDepartment, patientInfo.has_oxygen, patientInfo.selectedDepartment_id, patientInfo.sex, patientInfo.what_gender_will_replaced, patientInfo.will_has_oxygen, setShowTransfer]);

  const PatientTypeRadios = useMemo(() => {
    return [
      {
        label: `Муж. (${department?.count_male_busy})`,
        value: 'male',
        disabled: !department?.count_male_busy,
        style: { width: '20%' }
      },
      {
        label: `М. О2 (${department?.count_male_o2_busy})`,
        value: 'male_o2',
        disabled: !department?.count_male_o2_busy,
        style: { width: '20%' }
      },
      {
        label: `Жен. (${department?.count_female_busy})`,
        value: 'female',
        disabled: !department?.count_female_busy,
        style: { width: '20%' }
      },
      {
        label: `Ж. О2 (${department?.count_female_o2_busy})`,
        value: 'female_o2',
        disabled: !department?.count_female_o2_busy,
        style: { width: '20%' }
      },
      {
        label: `Д (${department?.count_children_busy})`,
        value: 'children',
        disabled: !department?.count_children_busy,
        style: { width: '20%' }
      }
    ];
  }, [department]);

  const PatientTypeChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    switch (value) {
      case 'male':
        setPatientInfo(prevState => ({
          ...prevState,
          has_oxygen: false,
          sex: 'MALE'
        }));
        break;
      case 'male_o2':
        setPatientInfo(prevState => ({
          ...prevState,
          has_oxygen: true,
          sex: 'MALE'
        }));
        break;
      case 'female':
        setPatientInfo(prevState => ({
          ...prevState,
          has_oxygen: false,
          sex: 'FEMALE'
        }));
        break;
      case 'female_o2':
        setPatientInfo(prevState => ({
          ...prevState,
          has_oxygen: true,
          sex: 'FEMALE'
        }));
        break;
      case 'children':
        setPatientInfo(prevState => ({
          ...prevState,
          has_oxygen: false,
          sex: 'CHILDREN'
        }));
        break;
      default :
        break;
    }
  };

  const SelectedDepartmentPlaceTypeRadios = useMemo(() => {
    return [
      {
        label: `Муж. (${selectedDepartmentData?.count_male_free ?? 'Н/Д'})`,
        value: 'male', disabled: !selectedDepartmentData?.count_male_free, style: { width: '20%' }
      },
      {
        label: `М. O2 (${selectedDepartmentData?.count_male_o2_free ?? 'Н/Д'})`,
        value: 'male_o2', disabled: !selectedDepartmentData?.count_male_o2_free, style: { width: '20%' }
      },
      {
        label: `Жен. (${selectedDepartmentData?.count_female_free ?? 'Н/Д'})`,
        value: 'female', disabled: !selectedDepartmentData?.count_female_free, style: { width: '20%' }
      },
      {
        label: `Ж. O2 (${selectedDepartmentData?.count_female_o2_free ?? 'Н/Д'})`,
        value: 'female_o2',
        disabled: !selectedDepartmentData?.count_female_o2_free,
        style: { width: '20%' }
      },
      {
        label: `Д (${selectedDepartmentData?.count_children_free ?? 'Н/Д'})`,
        value: 'children',
        disabled: !selectedDepartmentData?.count_children_free,
        style: { width: '20%' }
      },
    ];
  }, [selectedDepartmentData]);

  const DepartmentPlaceTypeChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    switch (value) {
      case 'male':
        setPatientInfo(prevState => ({
          ...prevState,
          will_has_oxygen: false,
          what_gender_will_replaced: 'MALE'
        }));
        break;
      case 'male_o2':
        setPatientInfo(prevState => ({
          ...prevState,
          will_has_oxygen: true,
          what_gender_will_replaced: 'MALE'
        }));
        break;
      case 'female':
        setPatientInfo(prevState => ({
          ...prevState,
          will_has_oxygen: false,
          what_gender_will_replaced: 'FEMALE'
        }));
        break;
      case 'female_o2':
        setPatientInfo(prevState => ({
          ...prevState,
          will_has_oxygen: true,
          what_gender_will_replaced: 'FEMALE'
        }));
        break;
      case 'children':
        setPatientInfo(prevState => ({
          ...prevState,
          will_has_oxygen: false,
          what_gender_will_replaced: 'CHILDREN'
        }));
        break;
      default :
        break;
    }
  };

  const allDepartmentsName = useMemo(() => {
    return allDepartments?.map(departmentData => <Option value={departmentData.name}>{departmentData.name}</Option>);
  }, [allDepartments]);

  const footerButtons = useMemo(() => {
    const transferDisabled = !areAllKeysDefined(patientInfo);
    return (
      <>
        <Button key="back" onClick={handleCloseModal}>
          Отмена
        </Button>
        <Button type="primary" onClick={onClickTransferPatient} disabled={transferDisabled}>
          Перевести
        </Button>
      </>
    );
  }, [patientInfo, onClickTransferPatient, handleCloseModal]);

  return (
    <>
      <Modal
        width={800}
        className={styles.modalWindow}
        visible={true}
        destroyOnClose={true}
        centered
        title="Перевод пациента"
        onOk={onClickTransferPatient}
        onCancel={handleCloseModal}
        footer={footerButtons}
      >
        <div className={styles.contentWrapper}>
          <label className={styles.label}> Инфо о пациенте (кол-во пациентов в вашем отделении):
            <Radio.Group
              style={{ width: '100%', textAlign: 'center' }}
              options={PatientTypeRadios}
              onChange={PatientTypeChange}
              optionType="button"
              buttonStyle="solid"
              size='large'
            />
          </label>
          <label className={styles.label}> Отделение для перевода:
            <Select className={styles.select} id='select' placeholder={'Выберете отделение'} size={'large'}
                    value={patientInfo?.selectedDepartment_name}
                    onSelect={handleSelectDepartment}>
              {allDepartmentsName}
            </Select>
          </label>
          <label className={styles.label}> Нужный тип койки (кол-во доступных в выбранном отделении):
            <Radio.Group
              style={{ width: '100%', textAlign: 'center' }}
              disabled={!patientInfo?.selectedDepartment_id}
              options={SelectedDepartmentPlaceTypeRadios}
              onChange={DepartmentPlaceTypeChange}
              optionType="button"
              buttonStyle="solid"
              size='large'
            />
          </label>
        </div>
      </Modal>
    </>
  );
});