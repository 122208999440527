import { AxiosResponse } from 'axios';
import securedAxiosInstance from '../axiosSecured';
import { errorNotification, successNotification } from '../../Components/Notification/setOfNotifications';
import { DepartmentInfo, DepartmentStats } from './model/DepartmentInfo.model';
import { Hospital, HospitalStat } from './model/Hospital.model';
import { FreeBunksCount } from './model/BunksCountTypes/FreeBunksCount.model';
import { BusyBunksCount } from './model/BunksCountTypes/BusyBunksCount.model';
import { ResponseSuccess } from './model/ResponseSuccess.model';
import { Booking } from './model/Booking.model';
import { BunkInfo } from './model/BunkInfo.model';
import { UserAuthInfo } from './model/UserAuthInfo.model';
import { TransferRequest } from './model/TransferRequest.model';
import { ReservedBunkCount } from './model/ReservedBunkCount.model';
import { BookBunkRequest } from './model/BookBunkRequest.model';
import { BookedBunk } from './model/BookedBunk.model';
import { Sex } from './model/Sex.model';

export const covidApi = {
  /** Получение госпиталей видимых пользователю*/
  async getHospitals(params?:string): Promise<Hospital[]> {
    try {
      const response = await securedAxiosInstance.get(`/hospitals/${params ? params : ''}`);
      return response.data ?? [];
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return [];
    }
  },
  /** Получение информации о отделении по его ID */
  async getDepartmentById(selectedDepartmentID: number): Promise<DepartmentInfo | undefined> {
    try {
      const response = await securedAxiosInstance.get(`/hospitals/departments/${selectedDepartmentID}/`);
      return response.data;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return;
    }
  },

  async getHospitalStats (queryParams = {}) {
    try {
       return await securedAxiosInstance.get(`/hospitals/hospitals_stats/`, {
         params: queryParams
       });
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      // @ts-ignore
      return e.response;
    }
  },

  /** Перевод между отделениями */
   async transferPatient(patientInfo: TransferRequest): Promise<ResponseSuccess> {
    try {
      const response = await securedAxiosInstance.post('/hospitals/bunks/transfer/', {
        ...patientInfo,
        count: 1
      } as TransferRequest);
      successNotification(response.data)
      return ResponseSuccess.SUCCESS;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return ResponseSuccess.FAILURE;
    }
  },
  /** Изменение количества свободных и занятых коек в отделении*/
  async changeBunksInDepartment(data: FreeBunksCount & BusyBunksCount, department_id: number): Promise<ResponseSuccess> {
    try {
      const response = await securedAxiosInstance.post('/hospitals/bunks/multiple_change/', { ...data, department_id });
      successNotification(response.data);
      return ResponseSuccess.SUCCESS;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return ResponseSuccess.FAILURE;
    }
  },
  /** Забронировать койку*/
  async bookBunk(bookData: Booking): Promise<ResponseSuccess> {
        try {
          const response = await securedAxiosInstance.post('/hospitals/bunks/to_book/', bookData);
          successNotification(response.data);
          return ResponseSuccess.SUCCESS;
        } catch (e) {
          // @ts-ignore
          errorNotification(e.response);
          return ResponseSuccess.FAILURE;
        }
  },
  /** Занять койку*/
  async setBunk(bookData: BookBunkRequest): Promise<ResponseSuccess> {
    try {
      const response = await securedAxiosInstance.post('/hospitals/bunks/take/', bookData);
      successNotification(response.data);
      return ResponseSuccess.SUCCESS;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return ResponseSuccess.FAILURE;
    }
  },
  /** добавление коек в отделении */
  async addBunksToDepartment(department_id: number, sex: Sex, has_oxygen: boolean, busy_count: number, free_count: number): Promise<ResponseSuccess> {
    try {
      const response = await securedAxiosInstance.post('/hospitals/bunks/multiple_addition/', {
        department_id,
        sex,
        has_oxygen,
        busy_count,
        free_count
      });
      successNotification(response.data);
      return ResponseSuccess.SUCCESS;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return ResponseSuccess.FAILURE;
    }
  },
  /** Удаление коек в отделении */
  async deleteOneBunk(department_id: number, sex: Sex, has_oxygen: boolean, busy_count: number, free_count: number): Promise<ResponseSuccess> {
    try {
      const response = await securedAxiosInstance.post('/hospitals/bunks/multiple_deletion/', {
        department_id,
        sex,
        has_oxygen,
        busy_count,
        free_count
      });
      successNotification(response.data);
      return ResponseSuccess.SUCCESS;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return ResponseSuccess.FAILURE;
    }
  },
  async getBookList() {
    try {
      const response = await securedAxiosInstance.get('/hospitals/reservations/');
      return response?.data ?? [];
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return [];
    }
  },
  /** Получение количества броней */
  async getBookInDepartmentsCount(): Promise<ReservedBunkCount | undefined> {
    try {
      const response = await securedAxiosInstance.get('/hospitals/departments/reservations/');
      return response?.data;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return;
    }
  },
  /** Удалить бронь по ID брони*/
  async removeBookingRecord(reservation_id: string): Promise<ResponseSuccess> {
    try {
      const checkResponse = await securedAxiosInstance.get(`/hospitals/reservations/?reservation_id=${reservation_id}`);
      if (checkResponse.data.length!==1) {
        // noinspection ExceptionCaughtLocallyJS
        errorNotification(null, 'Ошибка бронирования');
        return ResponseSuccess.FAILURE;
      } else {
        try {
          const response = await securedAxiosInstance.post('/hospitals/bunks/cancel_booking/', { reservation_id });
          successNotification(response.data);
          return ResponseSuccess.SUCCESS;
        } catch (e) {
          // @ts-ignore
          errorNotification(e.response);
          return ResponseSuccess.FAILURE;
        }
      }
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return ResponseSuccess.FAILURE;
    }
  },
  /** Освободить койку */
  async dischargePatient(bookData: {
    sex: Sex,
    has_oxygen: boolean,
    department_id: number,
  }): Promise<ResponseSuccess> {
    try {
      const response = await securedAxiosInstance.post('/hospitals/bunks/release/', bookData);
      successNotification(response.data);
      return ResponseSuccess.SUCCESS;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return ResponseSuccess.FAILURE;
    }
  },
  /** получение койки по номеру брони*/
  async searchBooking(reservation_id: string) : Promise<BookedBunk[]> {
    try {
      const response = await securedAxiosInstance.get(`/hospitals/reservations/?reservation_id=${reservation_id}`);
      return response.data;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return [];
    }
  },
  /** получение списка коек*/
  async getBunksList(): Promise<BunkInfo[]> {
    try {
      const response = await securedAxiosInstance.get('/hospitals/bunks/');
      return response?.data as BunkInfo[];
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return [];
    }
  },
  /** получение списка коек*/
  async getDepartments(): Promise<DepartmentInfo[]> {
    try {
      const response = await securedAxiosInstance.get('/hospitals/departments/');
      return response?.data;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return [];
    }
  },
  /** Запрос на получение групп и отделений пользователя */
  async getUserPermissionsAndGroups(): Promise<UserAuthInfo> {
    try {
      const response = await securedAxiosInstance.get('/users/addition_info/');
      return response?.data;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return {
        departments: [],
        groups: []
      };
    }
  },
  /** Статистика по отделениям, пока нигде не используется */
  async getDepartmentsStatistic(dateTimeFrom?:string, dateTimeTo?:string, departmentId?: number): Promise<Array<DepartmentStats>> {
    const params: { start_datetime?: string, end_datetime?: string, department?: number | string } = {};

    if (dateTimeFrom && dateTimeTo) {
      params.start_datetime = `${dateTimeFrom}`;
      params.end_datetime= `${dateTimeTo}`
    }

    if (departmentId) {
      params.department = departmentId;
    }
    try {
      const response = await securedAxiosInstance.get('/hospitals/departments_stats/',{
        params
      });
      return response?.data;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return [];
    }
  },
  /** Статистика по госпиталям */
  async getHospitalsStatisticFiltered(dateTimeFrom?: string, dateTimeTo?: string, hospitalId?: number): Promise<Array<HospitalStat>> {
    const params: { start_datetime?: string, end_datetime?: string, hospital?: number | string } = {};

    if (dateTimeFrom && dateTimeTo) {
      params.start_datetime = `${dateTimeFrom}`;
      params.end_datetime= `${dateTimeTo}`
    }

    if (hospitalId) {
      params.hospital = hospitalId;
    }
    try {
      const { data }: AxiosResponse = await securedAxiosInstance.get('/hospitals/hospitals_stats/',{
        params
      });
      return data;
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
      return [];
    }
  }
};