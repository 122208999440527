// @ts-ignore
import { DataView } from '@antv/data-set';
import { Chart } from '@antv/g2';
import { Hospital } from '../api/covidApi/model/Hospital.model';

export enum GenderTypes {
  Male = 'male',
  Female = 'female',
  Children = 'children'
}

export enum BunksType {
  Free = 'free',
  Busy = 'busy',
  Reservation = 'reservation',
}

export type ChartData = {
  value: number | string;
  type: string | number;
  name: string;
  shotName: string;
};

export enum ChartTypes {
  Reservation = 'бронь',
  Free = 'свободно',
  Busy = 'занято',
}

class ChartService {
  data: Hospital;
  chart: any;
  cartData?: Array<ChartData>;

  constructor(data: any) {
    this.data = data;
    this.initInitialData();
    this.init();
  }

  private initInitialData = () => {
    const bunks = this.calculateBunksPlaces();

    this.cartData = [
      {
        value: bunks[BunksType.Reservation][GenderTypes.Female],
        type: ChartTypes.Reservation,
        name: `Женские забронированные`,
        shotName: 'Ж бр',
      },
      {
        value: bunks[BunksType.Reservation][GenderTypes.Male],
        type: ChartTypes.Reservation,
        name: `Мужские забронированные`,
        shotName: 'М бр',
      },
      {
        value: bunks[BunksType.Reservation][GenderTypes.Children],
        type: ChartTypes.Reservation,
        name: `Детские забронированные`,
        shotName: 'Д бр',
      },
      {
        value: bunks[BunksType.Free][GenderTypes.Female],
        type: ChartTypes.Free,
        name: `Женские свободные`,
        shotName: 'Ж св',
      },
      {
        value: bunks[BunksType.Free][GenderTypes.Male],
        type: ChartTypes.Free,
        name: `Мужские свободные`,
        shotName: 'М св',
      },
      {
        value: bunks[BunksType.Free][GenderTypes.Children],
        type: ChartTypes.Free,
        name: `Детские свободные`,
        shotName: 'Д св',
      },
      {
        value: bunks[BunksType.Busy][GenderTypes.Female],
        type: ChartTypes.Busy,
        name: `Женские занятые`,
        shotName: 'Ж зн',
      },
      {
        value: bunks[BunksType.Busy][GenderTypes.Male],
        type: ChartTypes.Busy,
        name: `Мужские занятые`,
        shotName: 'М зн',
      },
      {
        value: bunks[BunksType.Busy][GenderTypes.Children],
        type: ChartTypes.Busy,
        name: `Детские занятые`,
        shotName: 'Д зн',
      },
    ];
  };

  calculateBunksPlaces = () => {
    const data = this.data;

    const result = {
      [BunksType.Free]: {
        [GenderTypes.Female]: 0,
        [GenderTypes.Male]: 0,
        [GenderTypes.Children]: 0,
      },
      [BunksType.Busy]: {
        [GenderTypes.Female]: 0,
        [GenderTypes.Male]: 0,
        [GenderTypes.Children]: 0,
      },
      [BunksType.Reservation]: {
        [GenderTypes.Female]: 0,
        [GenderTypes.Male]: 0,
        [GenderTypes.Children]: 0,
      },
    };
    Object.keys(data).forEach((key: string) => {
      // Busy
      if (key.includes(BunksType.Busy)) {
        if (key === 'count_male_busy' || key === 'count_male_o2_busy') {
          result[BunksType.Busy][GenderTypes.Male] =
            result[BunksType.Busy][GenderTypes.Male] + data[key];
        }
        if (key === 'count_female_busy' || key === 'count_female_o2_busy') {
          result[BunksType.Busy][GenderTypes.Female] =
            result[BunksType.Busy][GenderTypes.Female] + data[key];
        }
        if (key === 'count_children_busy') {
          result[BunksType.Busy][GenderTypes.Children] =
            result[BunksType.Busy][GenderTypes.Children] + data[key];
        }
      }

      // Free
      if (key.includes(BunksType.Free)) {
        if (key === 'count_male_free' || key === 'count_male_o2_free') {
          result[BunksType.Free][GenderTypes.Male] =
            result[BunksType.Free][GenderTypes.Male] + data[key];
        }
        if (key === 'count_female_free' || key === 'count_female_o2_free') {
          result[BunksType.Free][GenderTypes.Female] =
            result[BunksType.Free][GenderTypes.Female] + data[key];
        }
        if (key === 'count_children_free') {
          result[BunksType.Free][GenderTypes.Children] =
            result[BunksType.Free][GenderTypes.Children] + data[key];
        }
      }
      // Revisions
      if (key.includes(BunksType.Reservation)) {
        if (key === 'count_male_reservations' || key === 'count_male_o2_reservations') {
          result[BunksType.Reservation][GenderTypes.Male] =
            result[BunksType.Reservation][GenderTypes.Male] + data[key];
        }
        if (key === 'count_female_reservations' || key === 'count_female_o2_reservations') {
          result[BunksType.Reservation][GenderTypes.Female] =
            result[BunksType.Reservation][GenderTypes.Female] + data[key];
        }
        if (key === 'count_children_reservations') {
          result[BunksType.Reservation][GenderTypes.Children] =
            result[BunksType.Reservation][GenderTypes.Children] + data[key];
        }
      }
    });
    return result;
  };

  init = () => {
    const { cartData: data = [] } = this;

    const dv = new DataView();
    dv.source(data).transform({
      type: 'percent',
      field: 'value',
      dimension: 'type',
      as: 'percent',
    });
    this.chart = new Chart({
      container: `dashboard_item_${this.data.id}`,
      autoFit: true,
      height: 500,
      padding: 0,
    });
    this.chart.data(dv.rows);
    this.chart.scale({
      percent: {
        formatter: (val: any) => {
          val = (val * 100).toFixed(2) + '%';
          return val;
        },
      },
    });
    this.chart.coordinate('theta', {
      radius: 0.5,
    });
    this.chart.tooltip({
      showTitle: false,
      showMarkers: false,
    });
    this.chart.legend(true);
    this.chart
      .legend('name', {
        offsetY: 30,
        clickable: false,
      })
      .legend('type', {
        offsetY: 30,
        clickable: false,
      })
      .legend('value', {
        offsetY: 30,
        clickable: false,
      })
      .interval()
      .adjust('stack')
      .position('percent')
      .color('type', ['#FBB14F', '#85DB4C', '#FB9081'])
      .label('type', {
        offset: -10,
        content: (obj: any) => {
          return `${obj.value}`;
        },
      })
      .tooltip('type*percent', (item: any, percent: any) => {
        // определение имени во всплывающих подсказках внутри круга
        percent = (percent * 100).toFixed(2) + '%';
        return {
          name:item,
          value: percent,
        };
      })
      .style({
        lineWidth: 1,
        stroke: '#fff',
      });

    const outterView = this.chart.createView();
    const dv1 = new DataView();
    dv1.source(data).transform({
      type: 'percent',
      field: 'value',
      dimension: 'name',
      as: 'percent',
    });

    outterView.data(dv1.rows);
    outterView.scale({
      percent: {
        formatter: (val: any) => {
          val = (val * 100).toFixed(2) + '%';
          return val;
        },
      },
    });
    outterView.coordinate('theta', {
      innerRadius: 0.5 / 0.75,
      radius: 0.75,
    });
    outterView
      .interval()
      .adjust('stack')
      .position('percent')
      .color('name', ['#FFE9B9', '#FFD591', '#FFC76E', '#DBFFB7', '#A2F26D', '#85DB4C', '#FFC8C4', '#FFA39E', '#F57C76'])
      .label('shotName')
      .tooltip('name*percent', (item: any, percent: any) => {
        // определение имени во всплывающих подсказках в наружнем круге
        percent = (percent * 100).toFixed(2) + '%';
        return {
          name: item,
          value: percent,
        };
      })
      .style({
        lineWidth: 1,
        stroke: '#fff',
      });

    this.chart.interaction('element-highlight');
  };

  render = () => {
    this.chart.render();
  };

  clear = () => {
    this.chart.destroy();
  };
}

export default ChartService;
