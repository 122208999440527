import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Select } from 'antd';
// @ts-ignore
import styles from '../ModalBunkBooking/styles/ModalBunkBooking.module.css';
import { covidApi } from '../../../../api/covidApi/covidApi';
import { ModalDeleteBunkBookingProps } from './model/ModalDeleteBunkBooking.model';
import { BookingListItem } from '../../../BookingList/model/BookingListItem.model';
import moment from 'moment';

const { Option } = Select;

export const ModalDeleteBunkBooking = React.memo(({ setIsModalVisible, uploadHospitals }: ModalDeleteBunkBookingProps) => {
  const [loading, setLoading] = useState(false);
  const [reservationId, setReservationId] = useState<string | undefined>();
  const [bookingList, setBookingList] = useState<BookingListItem[]>([]);

  const uploadBookingList = () => {
    covidApi.getBookList()
      .then(result => setBookingList(result));
  };

  useEffect(() => {
    uploadBookingList();
  }, []);

  // Отправка данных при нажатии "ок" - модалка будет висеть и будет показана крутилка, пока не запостятся данные, по-идее
  const handleDeleteBunkBooking = useCallback(() => {
    if (reservationId) {
      setLoading(true);
      covidApi.removeBookingRecord(reservationId)
        .then(result => {
          if (result) {
            uploadHospitals()
            setIsModalVisible(false);
          }
        });
      setLoading(false);
    }
  }, [reservationId, setIsModalVisible, uploadHospitals]);

  // закрытие модалки
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const bookingOptions = useMemo(() => {
    return bookingList?.map(item => (
      <Option
        value={`${item.reservation_id}/${item.hospital_name}/${moment(item.date_time).format('DD.MM.YYYY HH:mm')}`}>
        {`${item.reservation_id}/${item.hospital_name}/${moment(item.date_time).format('DD.MM.YYYY HH:mm')}`}
      </Option>));
  }, [bookingList]);

  const handleSelectBookingForDelete = (value: string) => {
    const reservation_id = value.split('/')[0];
    const foundBooking = bookingList.find(item => item.reservation_id === reservation_id);
    if (foundBooking?.reservation_id) {
      setReservationId(foundBooking.reservation_id);
    }
  };

  return (
    <>
      <Modal
        width={600}
        className={styles.modalWindow}
        visible={true}
        destroyOnClose={true}
        centered
        title="Снять бронирование"
        onOk={handleDeleteBunkBooking}
        onCancel={() => handleCancel()}
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleDeleteBunkBooking}>
            Снять бронирование
          </Button>
        ]}
      >
        <div className={styles.contentWrapper}>
          <label className={styles.label}> Идентификатор брони: ( Формат даты: ДД.ММ.ГГГГ )
            <Select className={styles.select} placeholder='Бронь' size='large'
                    value={reservationId}
                    onChange={handleSelectBookingForDelete}
                    showSearch
            >
              {bookingOptions}
            </Select>
          </label>
        </div>
      </Modal>
    </>
  );
});