import React from 'react';
import {Button, Modal} from 'antd';
import {TableInformationAll} from "./common/Table";

export const ModalAllInformation = React.memo((props: {setShowAllInformation : (value: boolean) => void}) => {
    const {setShowAllInformation} = props

    const  handleCloseModal = React.useCallback(()=>{
        setShowAllInformation(false)
    },[setShowAllInformation])

    return (
        <div >
            <Modal
                width={"auto"}
                visible={true}
                destroyOnClose={true}
                centered
                title="Информация"
                onCancel={handleCloseModal}
                footer={
                    <>
                        <Button onClick={handleCloseModal} key="cancel" >
                            Закрыть
                        </Button>
                    </>
                }
            >
                <TableInformationAll/>
            </Modal>
        </div>
    );
});