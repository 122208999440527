import React, { useEffect, useState, Fragment, useCallback, useMemo } from 'react';
import DashboardItem from '../../Components/DashboardItem';
import { Row, Spin, Result, Button, Col, Select } from 'antd';
import { covidApi } from '../../api/covidApi/covidApi';
import { HospitalStat } from '../../api/covidApi/model/Hospital.model';
import { Hospital } from '../../api/covidApi/model/Hospital.model';
import DashboardMainStat from '../../Components/DashboardMainStat';
import moment from 'moment';
import { errorNotification } from '../../Components/Notification/setOfNotifications';

export type DashboardProps = {};
var _ = require('underscore-contrib');

const DashboardView: React.FC<DashboardProps> = () => {
  const [data, setData] = useState<Array<Hospital>>([]);
  const [mainState, setMainData] = useState<Array<HospitalStat>>([]);
  const [fullData, setFullData] = useState<Array<HospitalStat>>([]);
  const [selectedData, setSelectedData] = useState<Array<Hospital>>([]);
  const [selectedHospitals, setSelectedHospitals] = useState<{ selectedItems: string[] }>({ selectedItems: [] });
  const [fetching, setFetching] = useState<boolean>(true);

  const fetchHospitalStats = useCallback(async () => {
    try {
      const dateFrom = moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
      const dateTo = moment().format('YYYY-MM-DD HH:mm:ss');
      const data = await covidApi.getHospitalsStatisticFiltered(dateFrom, dateTo);
      const hospitalData = await covidApi.getHospitals('?dynamic=true');
      setData(hospitalData);
      setMainData(data);
      setSelectedData(hospitalData);
      setFullData(data);
    } catch (e) {
      errorNotification(null, `Ошибка при попытке отображения графиков. text-error: ${e}`)
    }

  }, []);

  const fetchInitialData = useCallback(async () => {
    try {
      await fetchHospitalStats();
    } finally {
      setFetching(false);
    }
  }, [fetchHospitalStats]);

  useEffect(() => {
      fetchInitialData();
  }, [fetchInitialData]);

  const selectHospitals = useCallback(async (selectedItems: string[]) => {
    try {
      setSelectedHospitals({ selectedItems });
      const newHospitalData = !selectedItems.length
        ? selectedData : selectedData.filter( (el) => selectedItems.includes(el.name));
      setData(newHospitalData);
      const newData = !selectedItems.length
        ? fullData : fullData.filter((el) => selectedItems.includes(el.hospital_name));
      setMainData(newData);
    } catch (e) {
      errorNotification(null, `Ошибка при попытке отображения графиков. text-error: ${e}`)
    } finally {
    }
  }, [fullData, selectedData]);

  const { selectedItems } = selectedHospitals;
  const filteredOptions = selectedData.filter(el => {
    return !selectedItems?.includes(el.name)});

  const notLoaded = useMemo(()=>{
    return (<div
    style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      top: 0,
      left: 0
    }}
  >
    <Spin size="large" />
  </div>)},[])

  const charts = useMemo(()=>{
    return (
      <>
        <Row>
          <Col span={24}>
            <DashboardMainStat data={mainState} />
          </Col>
        </Row>
        {data?.length ? (
          <Row gutter={[12, 12]} style={{ marginTop: 36 }}>
            {data.map((i: Hospital) => {
              /**
               * TODO Не понял как типизировать - увидите - типизируйте, пожалуйста :)
               */
              let currentHospitalData:any = [] ;
              _.map(i, (value:any, key:any) => {
                if (key.includes('count_')) {
                  currentHospitalData = { ...currentHospitalData,  [key]: value }
                }})
              if (_.values(currentHospitalData).some((el: number) => el > 0)) {
                return <DashboardItem key={i.id} data={i} />;
              }
              return null;
            })}
          </Row>
        ) : (
          <Result
            status="404"
            title="No data"
            extra={
              <Button loading={fetching} onClick={fetchInitialData}>
                Load again
              </Button>
            }
          />
        )}
      </>
    )
  },[data, fetchInitialData, fetching, mainState])

  const statisticsElements = useMemo(() => {
    return (
      <>
        {fetching && notLoaded}
        {data.length ? !fetching && charts : null}
      </>
    );
  }, [charts, data, fetching, notLoaded]);

  return (
    <Fragment>
      {selectedData.length ? <Select
        mode="multiple"
        placeholder="Выберите отделения, статистику по которым нужно отобразить"
        value={selectedData.filter(el => selectedItems.includes(el.name)).map(el => el.name)}
        onChange={(selectedItems) => selectHospitals(selectedItems)}
        style={{ width: '100%' }}
      >
        {filteredOptions.map(item => (
          <Select.Option key={item.id} value={item.name}>
            {item.name}
          </Select.Option>
        ))}
      </Select> : null}
      {statisticsElements}
    </Fragment>
  );
};

export default DashboardView;
