import React, { useState } from 'react';
import { Loader } from '../Components/Loader/Loader';

interface IProviderProps {
  children?: any;
}

type LoaderContextState = boolean

// noinspection JSUnusedLocalSymbols
export const loadingContextDefaultValue = {
  loading: false,
  setLoading: (state: LoaderContextState) => {
  } // noop default callback
};

export const LoadingContext = React.createContext(loadingContextDefaultValue);

export const LoadingProvider = (props: IProviderProps) => {
  const [loading, setLoading] = useState(loadingContextDefaultValue.loading);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {loading && <Loader />}
      {props.children}
    </LoadingContext.Provider>
  );
};