// import { FreeBunksCount } from './BunksCountTypes/FreeBunksCount.model';
// import { BusyBunksCount } from './BunksCountTypes/BusyBunksCount.model';
// import { ReservedBunksCount } from './BunksCountTypes/ReservedBunksCount.model';

export enum ChartKeys {
  Day = 'day',
  Hour = 'hour',
  All = 'всего коек',
  Busy = 'занято',
  Free = 'свободно',
}

export interface ChartData {
  [ChartKeys.Day]: string | number;
  [ChartKeys.All]: string | number;
  [ChartKeys.Busy]: string | number;
  [ChartKeys.Free]: string | number;
}

export enum StatFilters {
  BusyBunksCount = 'busy',
  FreeBunksCount = 'free',
  ReservedBunksCount = 'reservations',
}

export interface Hospital {
  hospital_type: string;
  id: number;
  name: string;
  phone: string | null;
  address: string;
  count_female_busy: number;
  count_female_free: number;
  count_female_o2_busy: number;
  count_female_o2_free: number;
  count_female_o2_reservations: number;
  count_female_reservations: number;
  count_male_busy: number;
  count_male_free: number;
  count_male_o2_busy: number;
  count_male_o2_free: number;
  count_male_o2_reservations: number;
  count_male_reservations: number;
  count_children_busy: number;
  count_children_free: number;
  count_children_reservations: number;
  dynamic?: {female: number, female_o2: number, male: number, male_o2: number, total: number};
}

export interface HospitalStat {
  [field: string]: any;
  count_female_busy: number;
  count_female_free: number;
  count_female_o2_busy: number;
  count_female_o2_free: number;
  count_female_o2_reservations: number;
  count_female_reservations: number;
  count_male_busy: number;
  count_male_free: number;
  count_male_o2_busy: number;
  count_male_o2_free: number;
  count_male_o2_reservations: number;
  count_male_reservations: number;
  datetime: string;
  hospital: Hospital;
  hospital_name: string;
  hospital_id: number;
  hospital_type: string;
  hospital_phone: string;
  hospital_address: string;
}
