import React from "react";
import { notification } from 'antd';
import { SmileOutlined, FrownOutlined } from '@ant-design/icons';

interface IOpenNotification {
  title: string;
  description: React.ReactNode;
  type: string;
}

export const openNotification = ({ title, description, type }: IOpenNotification) => {

  let icon: React.ReactNode;
  let backgroundColor;

  switch (type) {
    case 'success':
      icon = <SmileOutlined style={{ color: '#108ee9' }}/>;
      backgroundColor = {backgroundColor: '#e6edfc'};
      break;
    case 'error':
      icon = <FrownOutlined style={{ color: '#c23a3a' }}/>;
      backgroundColor = {backgroundColor: '#ffcccc'};
      break;
    default:
      break;
  }

  notification.open({
    message: title,
    description: description,
    icon: icon,
    placement: 'bottomRight',
    duration: 10,
    style: backgroundColor,
  });
};