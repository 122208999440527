import React, { FC, PropsWithChildren } from 'react';
import { Button as AntButton} from 'antd';
import { ButtonProps } from './model/Button.model';

export const Button : FC<PropsWithChildren<ButtonProps> > = ( {children,onClick, disabled, className}) => {
    return (
        <AntButton onClick = {onClick} type="primary" size="large" shape="round" disabled={disabled} className={className ?? ''}>{children}</AntButton>
    )
}

export const LogoutButton : FC<PropsWithChildren<ButtonProps> > = ( {children,onClick, disabled, className}) => {
    return (
        <AntButton onClick = {onClick} type="primary" size="large" disabled={disabled} danger className={className ?? ''}>{children}</AntButton>
    )
}