import React, { useMemo } from 'react';
import { Table } from 'antd';
import { DepartmentInfo } from '../../../../api/covidApi/model/DepartmentInfo.model';
import { ReservedBunkCount } from '../../../../api/covidApi/model/ReservedBunkCount.model';

// Колонки для таблицы всего
const COLUMNS_ALL = [
  {
    children: [
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
        width: 100
      },
      {
        dataIndex: 'freeMan',
        width: 40,
        align: 'center'
      },
      {
        dataIndex: 'freeManO2',
        width: 40,
        align: 'center'
      },
      {

        dataIndex: 'freeWoman',
        width: 40,
        align: 'center'
      },
      {
        dataIndex: 'freeWomanO2',
        width: 40,
        align: 'center'
      },
      {
        dataIndex: 'freeChildren',
        width: 40,
        align: 'center'
      },
      {
        dataIndex: 'stopMan',
        width: 40,
        align: 'center'
      },
      {
        dataIndex: 'stopManO2',
        width: 40,
        align: 'center'
      },
      {
        dataIndex: 'stopWomen',
        width: 40,
        align: 'center'
      },
      {
        dataIndex: 'stopWomenO2',
        width: 40,
        align: 'center'
      },
      {
        dataIndex: 'stopChildren',
        width: 40,
        align: 'center'
      },
    ]
  }
];

// столбцы для отделения
const COLUMNS_DEPARTMENTS = [
  {
    title: 'Название отделения',
    dataIndex: 'name',
    key: 'name',
    width: 100,
  },
  {
    title: 'Свободно',
    children: [
      {
        title: 'M',
        dataIndex: 'freeMan',
        key: 'freeMan',
        width: 40,
        align: 'center'
      },
      {
        title: 'M O2',
        dataIndex: 'freeManO2',
        key: 'freeManO2',
        width: 40,
        align: 'center'
      },
      {
        title: 'Ж',
        dataIndex: 'freeWoman',
        key: 'freeWoman',
        width: 40,
        align: 'center'
      },
      {
        title: 'Ж O2',
        dataIndex: 'freeWomanO2',
        key: 'freeWomanO2',
        width: 40,
        align: 'center'
      },
      {
        title: 'Д',
        dataIndex: 'freeChildren',
        key: 'freeChildren',
        width: 40,
        align: 'center'
      }
    ]
  },
  {
    title: 'Занято',
    dataIndex: 'stop',
    key: 'stop',
    // width: 100,
    children: [
      {
        title: 'M',
        dataIndex: 'stopMan',
        key: 'stopMan',
        width: 40,
        align: 'center'
      },
      {
        title: 'M O2',
        dataIndex: 'stopManO2',
        key: 'stopManO2',
        width: 40,
        align: 'center'
      },
      {
        title: 'Ж',
        dataIndex: 'stopWomen',
        key: 'stopWomen',
        width: 40,
        align: 'center'
      },
      {
        title: 'Ж O2',
        dataIndex: 'stopWomenO2',
        key: 'stopWomenO2',
        width: 40,
        align: 'center'
      },
      {
        title: 'Д',
        dataIndex: 'stopChildren',
        key: 'stopChildren',
        width: 40,
        align: 'center'
      }
    ]
  }
];

// столбцы для брони
const COLUMNS_RESERVE = [
  {
    title: 'Бронь по больнице',
    dataIndex: 'reservation',
    key: 'reservation',
    width: 100,
    className: 'test',
    children: [
      {
        title: 'M',
        dataIndex: 'reservationMan',
        key: 'reservationMan',
        width: 40,
        align: 'center'
      },
      {
        title: 'M O2',
        dataIndex: 'reservationManO2',
        key: 'reservationManO2',
        width: 40,
        align: 'center'
      },
      {
        title: 'Ж',
        dataIndex: 'reservationWoman',
        key: 'reservationWoman',
        width: 40,
        align: 'center'
      },
      {
        title: 'Ж O2',
        dataIndex: 'reservationWomanO2',
        key: 'reservationWomanO2',
        width: 40,
        align: 'center'
      },
      {
        title: 'Д',
        dataIndex: 'reservationChildren',
        key: 'reservationChildren',
        width: 40,
        align: 'center'
      }
    ]
  }
];

export const TableRegistrator = ({ departments, reservations }: {departments: DepartmentInfo[], reservations?: ReservedBunkCount}) => {

  // цикл для записи строк по отделениям
  const dataTableInDepartment = useMemo(
    () => {
      return departments.map((el, index) => ({
        key: index,
        name: el.name,

        freeMan: `${el.count_male_free}`,
        stopMan: `${el.count_male_busy}`,

        freeManO2: `${el.count_male_o2_free}`,
        stopManO2: `${el.count_male_o2_busy}`,

        freeWoman: `${el.count_female_free}`,
        stopWomen: `${el.count_female_busy}`,

        freeWomanO2: `${el.count_female_o2_free}`,
        stopWomenO2: `${el.count_female_o2_busy}`,

        freeChildren: `${el.count_children_free}`,
        stopChildren: `${el.count_children_busy}`,
      }));
    },
    [departments]
  );

  // запись строк по брони
  const dataTableReserve = useMemo(
    () => ({
      reservationMan: reservations?.male ?? 0,
      reservationManO2: reservations?.male_o2 ?? 0,
      reservationWoman: reservations?.female ?? 0,
      reservationWomanO2: reservations?.female_o2 ?? 0,
      reservationChildren: reservations?.children ?? 0,
    }),
    [reservations]
  );

  // строки по койкам и их сумма
  const dataAll = useMemo(
    () => ({
      key: 0,
      name: 'Всего',

      // map по койкам в отделении и их сумма
      freeMan: departments?.map((el) => el.count_male_free)
        .reduce((a, b) => a + b, 0),
      freeManO2: departments?.map((el) => el.count_male_o2_free)
        .reduce((a, b) => a + b, 0),
      freeWoman: departments?.map((el) => el.count_female_free)
        .reduce((a, b) => a + b, 0),
      freeWomanO2: departments?.map((el) => el.count_female_o2_free)
        .reduce((a, b) => a + b, 0),
      freeChildren: departments?.map((el) => el.count_children_free)
        .reduce((a, b) => a + b, 0),

      stopMan: departments?.map((el) => el.count_male_busy)
        .reduce((a, b) => a + b, 0),
      stopManO2: departments?.map((el) => el.count_male_o2_busy)
        .reduce((a, b) => a + b, 0),

      stopWomen: departments?.map((el) => el.count_female_busy)
        .reduce((a, b) => a + b, 0),
      stopWomenO2: departments?.map((el) => el.count_female_o2_busy)
        .reduce((a, b) => a + b, 0),
      stopChildren: departments?.map((el) => el.count_children_busy)
        .reduce((a, b) => a + b, 0),
    }),
    [departments]
  );

  // строки для таблицы "Всего (за вычетом брони)"
  const dataTableAllFreeMinusReserve = useMemo(
    () => ({
      key: 0,
      name: 'Всего (за вычетом брони)',

      // сумма всех коек по отделениям минус резерв
      freeMan: `${dataAll.freeMan - dataTableReserve.reservationMan}`,
      freeManO2: `${dataAll.freeManO2 - dataTableReserve.reservationManO2}`,
      freeWoman: `${dataAll.freeWoman - dataTableReserve.reservationWoman}`,
      freeWomanO2: `${dataAll.freeWomanO2 - dataTableReserve.reservationWomanO2}`,
      freeChildren: `${dataAll.freeChildren - dataTableReserve.reservationChildren}`,

      stopMan: `${departments?.map((el) => el.count_male_busy).reduce((a, b) => a + b, 0)}`,
      stopManO2: `${departments?.map((el) => el.count_male_o2_busy).reduce((a, b) => a + b, 0)}`,
      stopWomen: `${departments?.map((el) => el.count_female_busy).reduce((a, b) => a + b, 0)}`,
      stopWomenO2: `${departments?.map((el) => el.count_female_o2_busy).reduce((a, b) => a + b, 0)}`,
      stopChildren: `${departments?.map((el) => el.count_children_busy).reduce((a, b) => a + b, 0)}`,
    }),
    [dataAll, dataTableReserve, departments]
  );

  return (
    <div>
      <Table
        key='departments'
        sticky
        bordered
        columns={COLUMNS_DEPARTMENTS}
        dataSource={dataTableInDepartment}
        pagination={false}
      />
      <Table
        key='free'
        sticky
        bordered
        //@ts-ignore align задан нет так, как ожидается, однако работает
        columns={COLUMNS_ALL}
        dataSource={[dataTableAllFreeMinusReserve]}
        pagination={false}
      />
      <Table
        key='reservations'
        sticky
        bordered
        columns={COLUMNS_RESERVE}
        dataSource={[dataTableReserve]}
        pagination={false}
      />
    </div>
  );

};
