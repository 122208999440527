import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import {Layout, Menu} from 'antd';
import { Routes } from '../../routes/routes';
import DispatcherPage from '../Ambulance/Ambulance';
import HospitalDepartment from '../HospitalDepartment/HospitalDepartment';
import Hospital from '../Hospital/Hospital';
import { AuthContext, authContextDefaultValue } from '../../providers/AuthProvider';
import { RolesTypes } from '../../api/covidApi/model/User/RolesTypes';
import { doAuthCleanup } from '../../services/doAuthCleanup';
import {Button, LogoutButton} from '../../Components/Button/Button';
import { Logo } from "../../Components/Icon/Logo";
// @ts-ignore
import styles from './styles/Application.module.css';
import { covidApi } from '../../api/covidApi/covidApi';
import { SelectedDepartmentState } from './model/SelectedDepartmentState.model';
import { ModalChangeDepartment } from './components/ModalChangeDepartment/ModalChangeDepartment';
import DashboardView from "../../views/dashboard";

const {
  ROUTE_APP,
  ROUTE_DEPARTMENT,
  ROUTE_HOSPITAL,
  ROUTE_AMBULANCE,
  ROUTE_GUEST
} = Routes;

const { Header, Content } = Layout;
export const Application = () => {
  const [selectedDepartment, setSelectedDepartment] = useState<SelectedDepartmentState>(null);
  const [isModalChangeDepartmentVisible, setIsModalChangeDepartmentVisible] = useState<boolean>(false);
  const { authState, setAuthState } = useContext(AuthContext);
  const { groups, departments, username } = authState;

  const history = useHistory();

  useEffect(() => {
    if (username) {
      covidApi.getUserPermissionsAndGroups()
        .then(result => {
          setAuthState({
            groups: result.groups,
            departments: result.departments,
            username: username
          })
        });
    }
  }, [setAuthState, username]);


  const isSingleRole = useMemo(() => groups?.length === 1, [groups?.length]);

  useEffect(() => {
    if (isSingleRole) {
      if (groups[0] === RolesTypes.manager_department) {
        history.replace(`${ROUTE_APP}${ROUTE_DEPARTMENT}`);
      } else if (groups[0] === RolesTypes.manager_admission) {
        history.replace(`${ROUTE_APP}${ROUTE_HOSPITAL}`);
      } else if (groups[0] === RolesTypes.dispatcher) {
        history.replace(`${ROUTE_APP}${ROUTE_AMBULANCE}`);
      }
    }
  }, [groups, history, isSingleRole]);

  const workPlaces = useMemo(()=>{
    if (groups.includes(RolesTypes.manager_department)) {
      setSelectedDepartment(departments?.[0])}
    if (groups?.length && !isSingleRole){
      return (
        <Menu mode="horizontal" className={styles.menu}>
          <Menu.Item
              key={'ROUTE_APP'}
              onClick={()=>history.replace(`${ROUTE_APP}`)}
          >
            Главная
          </Menu.Item>
          {groups.includes(RolesTypes.manager_department) &&
          <Menu.Item
              key={'ROUTE_DEPARTMENT'}
              onClick={() => history.replace(`${ROUTE_APP}${ROUTE_DEPARTMENT}`)}
          >
            Отв. отделением
          </Menu.Item>
          }
          {groups.includes(RolesTypes.manager_admission) &&
          <Menu.Item
              key={'ROUTE_HOSPITAL'}
              onClick={()=>history.replace(`${ROUTE_APP}${ROUTE_HOSPITAL}`)}
          >
            Приемное отделение
            </Menu.Item>
          }
          {groups.includes(RolesTypes.dispatcher) &&
          <Menu.Item
              key={'ROUTE_AMBULANCE'}
              onClick={()=>history.replace(`${ROUTE_APP}${ROUTE_AMBULANCE}`)}
          >
            Скорая помощь
          </Menu.Item>
          }
        </Menu>)
    } else {
      return null;
    }
  },[departments, groups, history, isSingleRole]);

  const departmentSelector = useMemo(() => {
    return (
      <>
        <div className={styles.showingDepartmentName}>
          <p className={styles.currentDepartmentTitle}>{selectedDepartment?.name}</p>
          <Button size={'large'} className={styles.changingDepartment} onClick={() => {
            setIsModalChangeDepartmentVisible(true)
          }}>Сменить</Button>
        </div>
      </>
    );
  }, [selectedDepartment?.name]);

  const statisticsElements = useMemo(() => {
    if (groups.includes(RolesTypes.observer) || groups.includes(RolesTypes.chief_medical_officer)) {
      return (
          <div style={{padding: '36px'}}>
            <DashboardView/>
          </div>
      )
    }
    else return null
  }, [groups])

  const logoutUser = useCallback(() => {
    doAuthCleanup();
    setAuthState(authContextDefaultValue.authState);
    history.replace(`${ROUTE_GUEST}`);
  }, [history, setAuthState]);
  return (
    <>
      <Layout style={{ height: '100vh' }}>
        <Header>
          <LogoutButton className={styles.logoutButton} onClick={logoutUser}>Выйти</LogoutButton>
          {workPlaces}
          {history.location.pathname.includes('department') && departmentSelector}
          <div className={styles.textContainer}>
            <p className={styles.mainText}>Информационная система “Коечный фонд - бронирование”</p>
            <p className={styles.additionalText}>Департамент информатизации Тюменской области</p>
          </div>
          <Logo className={styles.logoImage}/>
        </Header>
        <Content>
          <Switch>
            <Route path={ROUTE_APP + ROUTE_AMBULANCE} component={DispatcherPage} />
            <Route path={ROUTE_APP + ROUTE_DEPARTMENT}>
              <HospitalDepartment selectedDepartment={selectedDepartment}/>
            </Route>
            <Route path={ROUTE_APP + ROUTE_HOSPITAL} component={Hospital} />
            <Route exact path={ROUTE_APP}>
              {statisticsElements}
            </Route>
          </Switch>
        </Content>
      </Layout>
      {
        isModalChangeDepartmentVisible &&
        <ModalChangeDepartment
          isModalVisible={isModalChangeDepartmentVisible}
          setModalVisible={setIsModalChangeDepartmentVisible}
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
        />
      }
    </>
  )
};