import React, {useCallback, useMemo, useState} from "react";
import {ModalDeleteBookingProps} from "./model/ModalDeleteBooking.model";
import {Button, Modal, Select} from "antd";
// @ts-ignore
import styles from './styles/ModalDeleteBooking.module.css';
import {BookingListItem} from "../../model/BookingListItem.model";
import moment from "moment";
import {covidApi} from "../../../../api/covidApi/covidApi";

const confirm = Modal.confirm;

const {Option} = Select;

export const ModalDeleteBooking = (props: ModalDeleteBookingProps) => {
    const {setIsModalVisible, bookingList, reloadBookingList} = props;

    const [selectedBooking, setSelectedBooking] = useState<BookingListItem>()

    const handleCloseModal = useCallback(() => {
        setIsModalVisible(false)
    }, [setIsModalVisible]);

    const deleteBookingRecord = useCallback((item: BookingListItem) => {
        confirm({
            title: 'Вы уверены что следует удалить бронь?',
            content: 'Бронь будет отменена, действие отменить невозможно',
            okText: 'Удалить бронь',
            cancelText: 'Выйти без удаления',
            onOk() {
                covidApi.removeBookingRecord(item.reservation_id)
                    .then(() => {
                            setIsModalVisible(false)
                            reloadBookingList()
                        }
                    )
            },
            onCancel() {

            },
        })
    }, [reloadBookingList, setIsModalVisible]);

    const handleProvideBunkDelete = useCallback(() => {
        if (selectedBooking) {
            deleteBookingRecord(selectedBooking)

        }
    }, [deleteBookingRecord, selectedBooking])

    const footerButtons = useMemo(() => {
        return (
            <>
                <Button key="back" onClick={handleCloseModal}>
                    Отмена
                </Button>
                <Button type="primary" onClick={handleProvideBunkDelete} disabled={!selectedBooking}>
                    Удалить
                </Button>
            </>
        );
    }, [handleCloseModal, handleProvideBunkDelete, selectedBooking]);

    const handleSelectBookingForDelete = (value: string) => {
        const reservation_id = value.split('/')[0]
        const foundBooking = bookingList.find(item => item.reservation_id === reservation_id)
        setSelectedBooking(foundBooking)
    }

    const bookingOptions = useMemo(() => {
        return bookingList?.map(item => (
            <Option
                value={`${item.reservation_id}/${item.hospital_name}/${moment(item.date_time).format('DD.MM.YYYY HH:mm')}`}>
                {`${item.reservation_id}/${item.hospital_name}/${moment(item.date_time).format('DD.MM.YYYY HH:mm')}`}
            </Option>))
    }, [bookingList])


    return (
        <>
            <Modal
                width={600}
                className={styles.modalWindow}
                visible={true}
                destroyOnClose={true}
                centered
                title="Удаление брони"
                onOk={handleProvideBunkDelete}
                onCancel={handleCloseModal}
                footer={footerButtons}
            >
                <div className={styles.contentWrapper}>
                    <label className={styles.label}>Идентификатор брони:
                    <Select className={styles.select} placeholder={'Бронь'} size={'large'}
                            value={selectedBooking?.reservation_id}
                            onSelect={handleSelectBookingForDelete}
                            showSearch
                    >
                        {bookingOptions}
                    </Select>
                    </label>
                </div>
            </Modal>
        </>
    )
}