export enum Routes {

  // Роуты гостя
  ROUTE_GUEST = '/guest',

  // Роуты приложения
  ROUTE_APP = '/app',

  // Роуты скорой помощи
  ROUTE_AMBULANCE = '/ambulance',
  ROUTE_BOOKING_LIST = '/booking-list',

  // Роуты заведующих отделением
  ROUTE_DEPARTMENT = '/department',



  // Роуты приемного отделения (больницы)
  ROUTE_HOSPITAL = '/hospital',
}