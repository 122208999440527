import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, InputNumber, Modal, Row } from 'antd';
import { ModalEditDepartmentProps } from './model/ModalEditDepartment.model';

export const ModalEditDepartment = React.memo((props:ModalEditDepartmentProps) => {
  const { setShowEdit, confirmCallback, data, fetchDepartment } = props;
  const initialValues = useMemo(()=>({
    count_male_busy: data.count_male_busy,
    count_male_free: data.count_male_free,
    count_male_total: data.count_male_busy + data.count_male_free,
    count_male_o2_busy: data.count_male_o2_busy,
    count_male_o2_free: data.count_male_o2_free,
    count_male_o2_total: data.count_male_o2_busy + data.count_male_o2_free,
    count_female_busy: data.count_female_busy,
    count_female_free: data.count_female_free,
    count_female_total: data.count_female_busy + data.count_female_free,
    count_female_o2_busy: data.count_female_o2_busy,
    count_female_o2_free: data.count_female_o2_free,
    count_female_o2_total: data.count_female_o2_busy + data.count_female_o2_free,
    count_children_busy: data.count_children_busy,
    count_children_free: data.count_children_free,
    count_children_total: data.count_children_busy + data.count_children_free,
  }),[data.count_children_busy, data.count_children_free, data.count_female_busy, data.count_female_free, data.count_female_o2_busy, data.count_female_o2_free, data.count_male_busy, data.count_male_free, data.count_male_o2_busy, data.count_male_o2_free]);

  const [values, setValues] = useState(initialValues);

  const handleInputChange = useCallback((value, key) => {
    setValues((prevState) => {
      let newValue
      const copy = {...prevState}
      /// count_male_busy
      if (key === "count_male_busy" && copy.count_male_total) {
        newValue = copy.count_male_total - value
        if (value > copy.count_male_total ) return {...prevState}
        return {
          ...prevState,
          [key]: value,
          count_male_free: newValue,
        }
      }
      if (key === "count_male_busy" && initialValues.count_male_total===0) {
        newValue = copy.count_male_free + value
        return {
           ...prevState,
           [key]: value,
           count_male_total: newValue,
        }
      }
      //// count_male_free
      if (key === "count_male_free" && copy.count_male_total ) {
         newValue = copy.count_male_total - value
        if (value > copy.count_male_total ) return {...prevState}
        return {
          ...prevState,
          [key]: value,
          count_male_busy: newValue
        }
      }
      if (key === "count_male_free" && initialValues.count_male_total===0) {
            newValue = copy.count_male_busy + value
            return {
              ...prevState,
              [key]: value,
              count_male_total: newValue,
            }
          }
      ///// count_male_o2_busy
      if (key === "count_male_o2_busy" && copy.count_male_o2_total) {
         newValue = copy.count_male_o2_total - value
         if (value > copy.count_male_o2_total) return {...prevState}
         return {
           ...prevState,
           [key]: value,
           count_male_o2_free: newValue,
          }
        }
          if (key === "count_male_o2_busy" && initialValues.count_male_o2_total===0) {
            newValue = copy.count_male_o2_free + value
            return {
              ...prevState,
              [key]: value,
              count_male_o2_total: newValue,
            }
          }
      //// count_male_o2_free
          if (key === "count_male_o2_free" && copy.count_male_o2_total) {
            newValue = copy.count_male_o2_total - value
            if (value > copy.count_male_o2_total) return {...prevState}
            return {
              ...prevState,
              [key]: value,
              count_male_o2_busy: newValue
            }
          }
          if (key === "count_male_o2_free" && initialValues.count_male_o2_total===0) {
            newValue = copy.count_male_o2_busy + value
            return {
              ...prevState,
              [key]: value,
              count_male_o2_total: newValue,
            }
          }
      //// count_female_busy
          if (key === "count_female_busy" && copy.count_female_total) {
            newValue = copy.count_female_total - value
            if (value > copy.count_female_total) return {...prevState}
            return {
              ...prevState,
              [key]: value,
              count_female_free: newValue,
            }
          }
          if (key === "count_female_busy" && initialValues.count_female_total===0) {
            newValue = copy.count_female_free + value
            return {
              ...prevState,
              [key]: value,
              count_female_total: newValue,
            }
          }

          /// count_female_free
          if (key === "count_female_free" && copy.count_female_total) {
            newValue = copy.count_female_total - value
            if (value > copy.count_female_total) return {...prevState}
            return {
              ...prevState,
              [key]: value,
              count_female_busy: newValue,
            }
          }
          if (key === "count_female_free" && initialValues.count_female_total===0) {
            newValue = copy.count_female_busy + value
            return {
              ...prevState,
              [key]: value,
              count_female_total: newValue,
            }
          }

          /// count_female_o2_free
          if (key === "count_female_o2_free" && copy.count_female_o2_total) {
            newValue = copy.count_female_o2_total - value
            if (value > copy.count_female_o2_total) return {...prevState}
            return {
              ...prevState,
              [key]: value,
              count_female_o2_busy: newValue
            }
          }
          if (key === "count_female_o2_free" && initialValues.count_female_o2_total===0) {
            newValue = copy.count_female_o2_busy + value
            return {
              ...prevState,
              [key]: value,
              count_female_o2_total: newValue,
            }
          }

          /// count_female_o2_busy
          if (key === "count_female_o2_busy" && copy.count_female_o2_total) {
            newValue = copy.count_female_o2_total - value
            if (value > copy.count_female_o2_total) return {...prevState}
            return {
              ...prevState,
              [key]: value,
              count_female_o2_free: newValue
            }
          }
          if (key === "count_female_o2_busy" && initialValues.count_female_o2_total===0) {
            newValue = copy.count_female_o2_free + value
            return {
              ...prevState,
              [key]: value,
              count_female_o2_total: newValue,
            }
          }

        /// count_children_free
        if (key === "count_children_free" && copy.count_children_total) {
          newValue = copy.count_children_total - value
          if (value > copy.count_children_total) return {...prevState}
          return {
            ...prevState,
            [key]: value,
            count_children_busy: newValue
          }
        }
        if (key === "count_children_free" && initialValues.count_children_total===0) {
          newValue = copy.count_children_busy + value
          return {
            ...prevState,
            [key]: value,
            count_children_total: newValue,
          }
        }

        /// count_female_o2_busy
        if (key === "count_children_busy" && copy.count_children_total) {
          newValue = copy.count_children_total - value
          if (value > copy.count_children_total) return {...prevState}
          return {
            ...prevState,
            [key]: value,
            count_children_free: newValue
          }
        }
        if (key === "count_children_busy" && initialValues.count_children_total===0) {
          newValue = copy.count_children_free + value
          return {
            ...prevState,
            [key]: value,
            count_children_total: newValue,
          }
        }

          // all total counts
          if (key === "count_male_total") {
            if (value < copy.count_male_busy) return {...prevState}
            newValue =  value - copy.count_male_total
            return {
              ...prevState,
              [key]: value,
              count_male_free: copy.count_male_free + newValue
            }
          }

          if (key === "count_male_o2_total") {
            if (value < copy.count_male_o2_busy) return {...prevState}
            newValue =  value - copy.count_male_o2_total
            return {
              ...prevState,
              [key]: value,
              count_male_o2_free: copy.count_male_o2_free + newValue
            }
          }

          if (key === "count_female_total") {
            if (value < copy.count_female_busy) return {...prevState}
            newValue =  value - copy.count_female_total
            return {
              ...prevState,
              [key]: value,
              count_female_free: copy.count_female_free + newValue
            }
          }

          if (key === "count_female_o2_total") {
            if (value < copy.count_female_o2_busy) return {...prevState}
            newValue =  value - copy.count_female_o2_total
            return {
              ...prevState,
              [key]: value,
              count_female_o2_free: copy.count_female_o2_free + newValue
            }
          }

        if (key === "count_children_total") {
          if (value < copy.count_children_busy) return {...prevState}
          newValue =  value - copy.count_children_total
          return {
            ...prevState,
            [key]: value,
            count_children_free: copy.count_children_free + newValue
          }
        }

      return {
        ...prevState,
        [key]: value,}
    }
    );
  }, [initialValues.count_children_total, initialValues.count_female_o2_total, initialValues.count_female_total, initialValues.count_male_o2_total, initialValues.count_male_total]);

  // закрытие модалки
  const handleCloseModal = useCallback(() => {
    setShowEdit(false);
  }, [setShowEdit]);

  const handleSaveModal = useCallback(() => {
    const prepData = {...values}
    confirmCallback(prepData);
    fetchDepartment()
    setShowEdit(false);
  }, [confirmCallback, fetchDepartment, setShowEdit, values]);

  const handleResetClickMale = useCallback(() => {
    setValues(prevState => ({
      ...prevState,
      count_male_busy: initialValues.count_male_busy,
      count_male_total: initialValues.count_male_total
    }));
  }, [initialValues.count_male_busy, initialValues.count_male_total]);

  const handleResetClickMaleO2 = useCallback(() => {
    setValues(prevState => ({
      ...prevState,
      count_male_o2_busy: initialValues.count_male_o2_busy,
      count_male_o2_total: initialValues.count_male_o2_total
    }));
  }, [initialValues.count_male_o2_busy, initialValues.count_male_o2_total]);

  const handleResetClickFemale = useCallback(() => {
    setValues(prevState => ({
      ...prevState,
      count_female_busy: initialValues.count_female_busy,
      count_female_total: initialValues.count_female_total
    }));
  }, [initialValues.count_female_busy, initialValues.count_female_total]);

  const handleResetClickFemaleO2 = useCallback(() => {
    setValues(prevState => ({
      ...prevState,
      count_female_o2_busy: initialValues.count_female_o2_busy,
      count_female_o2_total: initialValues.count_female_o2_total
    }));
  }, [initialValues.count_female_o2_busy, initialValues.count_female_o2_total]);

  const handleResetClickChildren = useCallback(() => {
    setValues(prevState => ({
      ...prevState,
      count_female_o2_busy: initialValues.count_children_busy,
      count_female_o2_total: initialValues.count_children_total
    }));
  }, [initialValues.count_children_busy, initialValues.count_children_total]);

  const handleResetAll = useCallback(() => {
    setValues(initialValues);
  }, [initialValues]);

  return (
    <>
      <Modal
        width={600}
        style={{ padding: '20px' }}
        visible={true}
        destroyOnClose={true}
        centered
        title="Редактирование отделения"
        onCancel={handleCloseModal}
        footer={
          <>
            <Button key="cancel" onClick={handleCloseModal}>
              Отмена
            </Button>
            <Button type="primary" key="back" onClick={handleSaveModal}>
              Сохранить
            </Button>
          </>
        }
      >
        <Row justify="space-between" gutter={1} style={{ 'marginBottom': '20px' }}>
          <Col span={4} style={{ 'textAlign': 'center' }}>Тип коек</Col>
          <Col span={4} style={{ 'textAlign': 'center' }}>Всего</Col>
          <Col span={4} style={{ 'textAlign': 'center' }}>Занято</Col>
          <Col span={4} style={{ 'textAlign': 'center' }}>Свободно</Col>
          <Col span={4} style={{ 'textAlign': 'center' }}>Сбросить</Col>
        </Row>
        <Row justify="space-between" gutter={1} style={{ margin: '10px 0' }}>
          <Col span={4}>Муж.</Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_male_total}
                         onChange={(value) => handleInputChange(value, 'count_male_total')}
            />
          </Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_male_busy}
                         onChange={(value) => handleInputChange(value, 'count_male_busy')}
            />
          </Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_male_free}
                         onChange={(value) => handleInputChange(value, 'count_male_free')}
            />
          </Col>
          <Col span={4}>
            <Button size="small"
                    onClick={handleResetClickMale}
            >
              Сбросить
            </Button>
          </Col>
        </Row>
        <Row justify="space-between" gutter={1} style={{ margin: '10px 0' }}>
          <Col span={4}>Муж. О2</Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_male_o2_total}
                         onChange={(value) => handleInputChange(value, 'count_male_o2_total')}
            />
          </Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_male_o2_busy}
                         onChange={(value) => handleInputChange(value, 'count_male_o2_busy')}
            />
          </Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_male_o2_free}
                         onChange={(value) => handleInputChange(value, 'count_male_o2_free')}
            />
          </Col>
          <Col span={4}>
            <Button size="small" onClick={handleResetClickMaleO2}>
              Сбросить
            </Button>
          </Col>
        </Row>
        <Row justify="space-between" gutter={1} style={{ margin: '10px 0' }}>
          <Col span={4}>Жен</Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_female_total}
                         onChange={(value) => handleInputChange(value, 'count_female_total')}
            />
          </Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                                     value={values.count_female_busy}
                                     onChange={(value) => handleInputChange(value, 'count_female_busy')}
          />
          </Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_female_free}
                         onChange={(value) => handleInputChange(value, 'count_female_free')}
            />
          </Col>
          <Col span={4}>
            <Button size="small" onClick={handleResetClickFemale}>
              Сбросить
            </Button>
          </Col>
        </Row>
        <Row justify="space-between" gutter={1} style={{ margin: '10px 0' }}>
          <Col span={4}>Жен О2</Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_female_o2_total}
                         onChange={(value) => handleInputChange(value, 'count_female_o2_total')}
            />
          </Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_female_o2_busy}
                         onChange={(value) => handleInputChange(value, 'count_female_o2_busy')}
            />
          </Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_female_o2_free}
                         onChange={(value) => handleInputChange(value, 'count_female_o2_free')}
            />
          </Col>
          <Col span={4}>
            <Button size="small" onClick={handleResetClickFemaleO2}>
              Сбросить
            </Button>
          </Col>
        </Row>
        <Row justify="space-between" gutter={1} style={{ margin: '10px 0' }}>
          <Col span={4}>Дет.</Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_children_total}
                         onChange={(value) => handleInputChange(value, 'count_children_total')}
            />
          </Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_children_busy}
                         onChange={(value) => handleInputChange(value, 'count_children_busy')}
            />
          </Col>
          <Col span={4}>
            <InputNumber size="small" min={0} max={100000}
                         value={values.count_children_free}
                         onChange={(value) => handleInputChange(value, 'count_children_free')}
            />
          </Col>
          <Col span={4}>
            <Button size="small"
                    onClick={handleResetClickChildren}
            >
              Сбросить
            </Button>
          </Col>
        </Row>
        <Row justify="space-between" gutter={1} style={{ 'marginTop': '20px' }}>
          <Col span={4}>Всего</Col>
          <Col
            span={4}>{values.count_male_total + values.count_male_o2_total + values.count_female_total + values.count_female_o2_total + values.count_children_total}</Col>
          <Col
            span={4}>{values.count_male_busy + values.count_male_o2_busy + values.count_female_busy + values.count_female_o2_busy + values.count_children_busy}</Col>
          <Col
              span={4}>{values.count_male_free + values.count_male_o2_free + values.count_female_free + values.count_female_o2_free + values.count_children_free}</Col>
          <Col span={4}><Button size="small" onClick={handleResetAll}>Сбросить</Button></Col>
        </Row>
      </Modal>
    </>
  );
});