import React from 'react';
import { LocalNotification } from '../model/LocalNotification.model';
import styles from '../styles/ModalBunkBooking.module.css';

interface ILocalNotification {
  hospitalName?: string,
  typeOfNotification?: LocalNotification,
}

export const LocaleNotificationSetter = ({hospitalName, typeOfNotification}: ILocalNotification) => {
  switch (typeOfNotification) {
    case LocalNotification.notFound:
      return (
        <div className={styles.localNotification}>
          <div style={{ 'color': '#fc4e4e' }}>Такого идентификатора бронирования нет в системе</div>
        </div>
      );
    case LocalNotification.foundMoreThenOne:
      return (
        <div className={styles.localNotification}>
          <div style={{ 'color': '#fc4e4e' }}>Произошла критическая ошибка, есть несколько таких идентификаторов бронирования.
            Обратитесь в тех. поддержку, чтобы решить эту проблему</div>
        </div>
      );
    case LocalNotification.differentHospitals:
      return (
        <div className={styles.localNotification}>
          <div style={{ 'color': '#fc4e4e' }}>Этот идентификатор бронирования зарегистрирован
            для {hospitalName}! При госпитализации бронь будет удалена.
          </div>
        </div>
      );
    case LocalNotification.differentHospitalsWithoutPlaces:
      return (
        <div className={styles.localNotification}>
          <div style={{ 'color': '#fc4e4e' }}>Этот идентификатор бронирования зарегистрирован
            для {hospitalName}! При госпитализации бронь будет удалена.
          </div>
          <div style={{ 'color': '#A16D20' }}>
            В отделении нет коек данного типа.
          </div>
        </div>
      );
    case LocalNotification.noFreeSpaceInDepartment:
      return (
        <div className={styles.localNotification}>
          <div style={{ 'color': '#fc4e4e' }}>В этом отделении нет свободных коек нужного типа</div>
        </div>
      );
    case LocalNotification.departmentNotSelected:
      return (
        <div className={styles.localNotification}>
          <div style={{ 'color': '#fc4e4e' }}>Отделение не выбрано</div>
        </div>
      );
    case LocalNotification.success:
      return (
        <div className={styles.localNotification}>
          <div style={{ 'color': '#27AE60' }}>Этот идентификатор бронирования существует и зарегистрирован в данном
            госпитале
          </div>
        </div>
      );
    case LocalNotification.successWithoutPlaces:
      return (
        <div className={styles.localNotification}>
          <div style={{ 'color': '#A16D20' }}>Этот идентификатор бронирования существует и зарегистрирован в данном
            госпитале, но в отделении нет коек данного типа.
          </div>
        </div>
      );
    default:
      return null;
  }
}