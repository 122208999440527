import { axiosTemplate } from './axiosGuest';
import { errorNotification } from "../Components/Notification/setOfNotifications";

export const authApi = {
  /** Запрос на обновление токена */
  async refreshToken() {
    const refresh = localStorage.getItem('refresh');
    if (refresh ) {
      try {
        const username = localStorage.getItem('username');
        const response = await axiosTemplate.post("/users/token/refresh/", { refresh, username },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            }
          });
        const { access } = response.data;
        localStorage.setItem('access', access);
        return { access };
      } catch (e) {
        // @ts-ignore
        errorNotification(e.response, 'Ошибка при обновлении токена.')
        return null
      }
    } else {
      return null;
    }
  },
  /** Запрос для авторизации юзера */
  async login({ password, username }: { password: string, username: string }) {
    try {
      const response = await axiosTemplate.post('/users/token/', { username, password },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        }
      );
      const { refresh, access} = response?.data;
      localStorage.setItem('refresh', refresh);
      localStorage.setItem('access', access);
      localStorage.setItem('username', username);
      return { refresh, access, username };
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response, 'Логин и/или пароль введены неверно. Ошибка при авторизации.')
      return {
        refresh: null,
        access: null,
        username: null,
        groups: [],
        departments: [],
      }
    }
  }
}
