import { covidApi } from '../../../../../api/covidApi/covidApi';
import { BookedBunk } from '../../../../../api/covidApi/model/BookedBunk.model';
import { LocalNotification } from '../model/LocalNotification.model';
import { Department } from '../../../model/Department.model';
import { Dispatch, SetStateAction } from 'react';
import { DepartmentBookingState } from '../model/DepartmentBookingState.model';
import { LocalBookingInfoState, RadioIdentificationBookingType } from '../model/LocalBookingInfoState.model';
import { errorNotification } from '../../../../../Components/Notification/setOfNotifications';

interface ICheckIfHospitalsAreNotSame {
  currentBooking: BookedBunk;
  departments: Department[];
}

const checkIfHospitalsAreNotSame = ({ currentBooking, departments }: ICheckIfHospitalsAreNotSame): boolean => {
  const currentHospital = departments[0];
  if (currentHospital) {
    return currentHospital.hospital !== currentBooking.hospital;
  }
  return true;
};

interface ICheckIfNoFreePlacesInDepartment {
  departments: Department[];
  bookingInfo: DepartmentBookingState;
}

const checkIfNoFreePlacesInDepartment = ({ departments, bookingInfo }: ICheckIfNoFreePlacesInDepartment): boolean => {
  const currentDepartment = departments.find(department => department.id === bookingInfo.department_id);
  let generatedKey = 'count';
  if (bookingInfo.sex === 'MALE') {
    generatedKey = generatedKey + '_male';
  } else if (bookingInfo.sex === 'FEMALE') {
    generatedKey = generatedKey + '_female';
  } else if (bookingInfo.sex === 'CHILDREN') {
    generatedKey = generatedKey + '_children';
  }
  if (bookingInfo.has_oxygen) {
    generatedKey = generatedKey + '_o2';
  }
  generatedKey = generatedKey + '_free';
  if (currentDepartment) {
    // @ts-ignore
    const freePlaces = currentDepartment.count_bunks?.[generatedKey];
    return freePlaces <= 0;
  }
  return true;
};

interface IGetRadioIdentification {
  sexType: string;
  oxygenType: boolean;
}

const getRadioIdentification = ({ sexType, oxygenType }: IGetRadioIdentification) => {
  if (sexType && oxygenType !== undefined) {
    let identification;
    if (sexType === 'MALE') {
      identification = 'male';
    } else if (sexType === 'FEMALE') {
      identification = 'female';
    } else if (sexType === 'CHILDREN') {
      identification = 'children';
    }
    if (oxygenType) {
      identification += '_o2';
    }
    if (!identification) errorNotification(null, 'Не получилось определить идентификатор пациента');
    return identification as RadioIdentificationBookingType;
  } else {
    errorNotification(null, 'Не получилось сгенерировать идентификатор пациента');
  }
};

interface IBookingSearcher {
  value: string;
  bookingStateSetter: Dispatch<SetStateAction<DepartmentBookingState>>;
  modalStateSetter: Dispatch<SetStateAction<LocalBookingInfoState>>;
  departments: Department[];
  bookingInfo: DepartmentBookingState;
}

export const bookingSearcher = ({
                                  value,
                                  bookingStateSetter,
                                  modalStateSetter,
                                  departments,
                                  bookingInfo,
                                }: IBookingSearcher) => {
  covidApi.searchBooking(value).then(result => {
    /** обработка ответа с отсутствующими/некорректными данными. */
    if (!result.length) {
      modalStateSetter(prevState => ({
        ...prevState,
        localeNotification: LocalNotification.notFound
      }));
    } else if (result.length > 1) {
      modalStateSetter(prevState => ({
        ...prevState,
        localeNotification: LocalNotification.foundMoreThenOne
      }));
    } else { /** обработка корректного ответа. */
      // проверка выбора отдела и наличия в нём места
      if (!bookingInfo?.department_id) {
        modalStateSetter(prevState => ({
          ...prevState,
          localeNotification: LocalNotification.departmentNotSelected
        }));
      } else if (checkIfNoFreePlacesInDepartment({ departments, bookingInfo })) {
        modalStateSetter(prevState => ({
          ...prevState,
          localeNotification: LocalNotification.noFreeSpaceInDepartment
        }));
      } else {
        /** операции если все проверки выше прошли нормально */
        const stringRadioIdentification = getRadioIdentification({
          sexType: result[0].sex,
          oxygenType: result[0].has_oxygen
        });
        // проверка если госпитализирующий госпиталь не тот, что указан при бронировании
        const selectedDepartmentInfo = departments?.find(department => department?.id === bookingInfo?.department_id);
        const isPlaceInCurrDepartment = !!selectedDepartmentInfo?.[`count_${stringRadioIdentification}_free` as keyof Department];
        if (checkIfHospitalsAreNotSame({ currentBooking: result[0], departments })) {
          bookingStateSetter(prevState => ({
            ...prevState,
            ...result[0]
          }));
          modalStateSetter(prevState => ({
            ...prevState,
            radioIdentification: stringRadioIdentification,
            localeNotification: isPlaceInCurrDepartment ? LocalNotification.differentHospitals : LocalNotification.differentHospitalsWithoutPlaces
          }));
        } else {
          bookingStateSetter(prevState => ({
            ...prevState,
            ...result[0]
          }));
          modalStateSetter(prevState => ({
            ...prevState,
            radioIdentification: stringRadioIdentification,
            localeNotification: isPlaceInCurrDepartment ? LocalNotification.success : LocalNotification.successWithoutPlaces
          }));
        }
      }
    }
  });
};