import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Radio, Select } from 'antd';
// @ts-ignore
import styles from './styles/ModalBunkBooking.module.css';
import { covidApi } from '../../../../api/covidApi/covidApi';
import { ModalDischargeProps } from './model/ModalDischarge.model';
import { areAllKeysDefined } from '../../../../services/areAllKeysDefined';
import { PatientInfoLocalState } from './model/PatientInfoLocalState.model';
import { RadioChangeEvent } from 'antd/es/radio';

const { Option } = Select;

export const ModalDischarge = React.memo(({ allDepartments, setShowDischarge, fetchDepartment }: ModalDischargeProps) => {

    const [patientInfo, setPatientInfo] = useState<PatientInfoLocalState>({
        department_id: undefined,
        sex: undefined,
        has_oxygen: undefined
    });

    // закрытие модалки
    const handleCloseModal = useCallback(() => {
        setShowDischarge(false);
    }, [setShowDischarge]);

    const handleDischargePatient = useCallback(() => {
        if (patientInfo.department_id && typeof patientInfo.has_oxygen === 'boolean' && patientInfo.sex) {
            covidApi.dischargePatient( {
                department_id: patientInfo.department_id,
                has_oxygen: patientInfo.has_oxygen,
                sex: patientInfo.sex
            })
              .then(() => {
                  fetchDepartment()
                  setShowDischarge(false);
              });
        }
      },
      [fetchDepartment, patientInfo.department_id, patientInfo.has_oxygen, patientInfo.sex, setShowDischarge]);

    const footerButtons = useMemo(() => {
        const dischargeDisabled = !areAllKeysDefined(patientInfo);
        return (
          <>
              <Button key="back" onClick={handleCloseModal}>
                  Отмена
              </Button>
              <Button type="primary" onClick={handleDischargePatient} disabled={dischargeDisabled}>
                  Выписать
              </Button>
          </>
        );
    }, [patientInfo, handleDischargePatient, handleCloseModal]);

    const DepartmentOptions = useMemo(() => {
        return allDepartments?.map(department => <Option value={department.id}>{department.name}</Option>);
    }, [allDepartments]);

    const handleSelectDepartment = (departmentId: number) => {
        setPatientInfo(prevState => ({
            ...prevState,
            department_id: departmentId
        }));
    };

    const patientTypeRadios = useMemo(() => {
        const selectedDepartmentInfo = allDepartments?.find(department => department.id === patientInfo?.department_id);
        return [
            {
                label: 'Мужчина',
                style: { width: '20%' },
                value: 'male',
                disabled: !selectedDepartmentInfo?.count_male_busy
            },
            {
                label: 'Муж. О2',
                style: { width: '20%' },
                value: 'male_o2',
                disabled: !selectedDepartmentInfo?.count_male_o2_busy
            },
            {
                label: 'Женщина',
                style: { width: '20%' },
                value: 'female',
                disabled: !selectedDepartmentInfo?.count_female_busy
            },
            {
                label: 'Жен. О2',
                style: { width: '20%' },
                value: 'female_o2',
                disabled: !selectedDepartmentInfo?.count_female_o2_busy
            },
            {
                label: 'Ребёнок',
                style: { width: '20%' },
                value: 'children',
                disabled: !selectedDepartmentInfo?.count_children_busy
            },
        ];
    }, [patientInfo?.department_id, allDepartments]);

    const handleTypePatientChange = (event: RadioChangeEvent) => {
        const { value } = event.target;
        switch (value) {
            case 'male':
                setPatientInfo(prevState => ({
                    ...prevState,
                    has_oxygen: false,
                    sex: 'MALE'
                }));
                break;
            case 'male_o2':
                setPatientInfo(prevState => ({
                    ...prevState,
                    has_oxygen: true,
                    sex: 'MALE'
                }));
                break;
            case 'female':
                setPatientInfo(prevState => ({
                    ...prevState,
                    has_oxygen: false,
                    sex: 'FEMALE'
                }));
                break;
            case 'female_o2':
                setPatientInfo(prevState => ({
                    ...prevState,
                    has_oxygen: true,
                    sex: 'FEMALE'
                }));
                break;
            case 'children':
                setPatientInfo(prevState => ({
                    ...prevState,
                    has_oxygen: false,
                    sex: 'CHILDREN'
                }));
                break;
            default :
                break;
        }
    };

    return (
      <>
          <Modal
            width={600}
            className={styles.modalWindow}
            visible={true}
            destroyOnClose={true}
            centered
            title="Выписка"
            onOk={handleDischargePatient}
            onCancel={handleCloseModal}
            footer={footerButtons}
          >
              <div className={styles.contentWrapper}>
                  <label className={styles.label}> Отделение из которого нужно выписать:
                      <Select className={styles.select} placeholder={'Откуда'} size={'large'}
                              value={patientInfo?.department_id}
                              onSelect={handleSelectDepartment}>
                          {DepartmentOptions}
                      </Select>
                  </label>
                  <label className={styles.label}> Информация о пациенте:
                      <Radio.Group
                        style={{ width: '100%', textAlign: 'center' }}
                        disabled={!patientInfo?.department_id}
                        options={patientTypeRadios}
                        onChange={handleTypePatientChange}
                        optionType="button"
                        buttonStyle="solid"
                        size='large'
                      />
                  </label>
              </div>
          </Modal>
      </>
    );
});