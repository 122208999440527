import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import {covidApi} from "../../../../../api/covidApi/covidApi";
import { DepartmentInfo } from '../../../../../api/covidApi/model/DepartmentInfo.model';

const COLUMNS = [
    {
        title: 'Отделение',
        width: 110,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
    },
    { title: 'Занято М', dataIndex: 'count_male_busy', key: 'count_male_busy' },
    { title: 'Занято Мo2', dataIndex: 'count_male_o2_busy', key: 'count_male_o2_busy' },
    { title: 'Занято Ж', dataIndex: 'count_female_busy', key: 'count_female_busy' },
    { title: 'Занято Жo2', dataIndex: 'count_female_o2_busy', key: 'count_female_o2_busy' },
    { title: 'Занято Д', dataIndex: 'count_children_busy', key: 'count_children_busy' },

    { title: 'Свободно М', dataIndex: 'count_male_free', key: 'count_male_free' },
    { title: 'Свободно Мo2', dataIndex: 'count_male_o2_free', key: 'count_male_o2_free' },
    { title: 'Свободно Ж', dataIndex: 'count_female_free', key: 'count_female_free' },
    { title: 'Свободно Жо2', dataIndex: 'count_female_o2_free', key: 'count_female_o2_free' },
    { title: 'Свободно Д', dataIndex: 'count_children_free', key: 'count_children_free' },
];

export const TableInformationAll = () => {
    const [allDepartments, setAllDepartments] = useState<DepartmentInfo[] | []>([]);

    const fetchAllDepartments = useCallback(() => {
        covidApi.getDepartments()
            .then((result) => {
                setAllDepartments(result);
            });
    }, []);

    useEffect(()=>{
        fetchAllDepartments()
    },[fetchAllDepartments])

    const data = useMemo(() => {
        return allDepartments.map((department, index) => {
            return {
                key: index,
                name: department.name,

                count_male_busy: department.count_male_busy,
                count_male_o2_busy: department.count_male_o2_busy,
                count_female_busy: department.count_female_busy,
                count_female_o2_busy: department.count_female_o2_busy,
                count_children_busy: department.count_children_busy,

                count_male_free: department.count_male_free,
                count_male_o2_free: department.count_male_o2_free,
                count_female_free: department.count_female_free,
                count_female_o2_free: department.count_female_o2_free,
                count_children_free: department.count_children_free,
            }
        })
    },
      [allDepartments]
    );


    return(
      //@ts-ignore fixed задан нет так, как ожидается, однако работает TODO посмотреть нужен ли fixed вообще
        <Table columns={COLUMNS} dataSource={data} />
    )
}