import React, { useEffect } from 'react';
import MainStatChartService from '../../services/MainStatChartService';
export type DashboardMainStatProps = {
  data: any;
};

const containerId = 'main_stat';

const DashboardMainStat: React.FC<DashboardMainStatProps> = ({ data }) => {

  useEffect(() => {
    let service: any;
    if (data) {
      service = new MainStatChartService(containerId, data);
      service.render();
    }
    return () => {
      service?.clear();
    }
  }, [data])
  return (
    <div style={{marginTop: '40px'}} id={containerId} />
  )
};

export default DashboardMainStat;
