/** Роли пользователей приложения*/
export enum RolesTypes {
  /** Ответственный приемного отделения*/
  manager_admission = 'manager_admission',
  /** Заведующий отделением*/
  manager_department = 'manager_department',
  /** Старший врач скорой помощи */
  dispatcher = 'dispatcher',
  /** Глав. врач госпиталя */
  chief_medical_officer = 'chief_medical_officer',
  /** Сотрудник департамента здравоохранения */
  observer = 'observer',
  /** none */
  none = 'none'
}