import { Dispatch, SetStateAction } from 'react';
import { LocalBookingInfoState, RadioIdentificationBookingType } from '../model/LocalBookingInfoState.model';
import { DepartmentBookingState } from '../model/DepartmentBookingState.model';
import { IGuardianState } from '../model/GuardianBookingState.model';

export enum switcherTypes {
  main = 'main',
}

interface IPatientTypeRadioInputSwitcher {
  patientType?: RadioIdentificationBookingType;
  bookingStateSetter: Dispatch<SetStateAction<DepartmentBookingState>>;
  modalStateSetter: Dispatch<SetStateAction<LocalBookingInfoState>>;
  typeSwitcherChanged: 'main';
}

export const patientTypeRadioInputSwitcher = ({patientType, bookingStateSetter, modalStateSetter,
                                                typeSwitcherChanged}: IPatientTypeRadioInputSwitcher) => {
  switch (patientType) {
    case 'male':
      bookingStateSetter(prevState => ({
        ...prevState,
        has_oxygen: false,
        sex: 'MALE'
      }));
      if (typeSwitcherChanged === switcherTypes.main) modalStateSetter(prevState => ({
        ...prevState,
        radioIdentification: patientType,
      }));
      break;
    case 'male_o2':
      bookingStateSetter(prevState => ({
        ...prevState,
        has_oxygen: true,
        sex: 'MALE'
      }));
      if (typeSwitcherChanged === switcherTypes.main) modalStateSetter(prevState => ({
        ...prevState,
        radioIdentification: patientType,
      }));
      break;
    case 'female':
      bookingStateSetter(prevState => ({
        ...prevState,
        has_oxygen: false,
        sex: 'FEMALE'
      }));
      if (typeSwitcherChanged === switcherTypes.main) modalStateSetter(prevState => ({
        ...prevState,
        radioIdentification: patientType,
      }));
      break;
    case 'female_o2':
      bookingStateSetter(prevState => ({
        ...prevState,
        has_oxygen: true,
        sex: 'FEMALE'
      }));
      if (typeSwitcherChanged === switcherTypes.main) modalStateSetter(prevState => ({
        ...prevState,
        radioIdentification: patientType,
      }));
      break;
    case 'children':
      bookingStateSetter(prevState => ({
        ...prevState,
        has_oxygen: false,
        sex: 'CHILDREN'
      }));
      if (typeSwitcherChanged === switcherTypes.main) modalStateSetter(prevState => ({
        ...prevState,
        radioIdentification: patientType,
      }));
      break;
    default :
      break;
  }
};

interface IGuardianSexRadioInputSwitcher {
  patientType?: RadioIdentificationBookingType;
  bookingStateSetter: Dispatch<SetStateAction<IGuardianState>>;
  modalStateSetter: Dispatch<SetStateAction<LocalBookingInfoState>>;
}

export const guardianSexRadioInputSwitcher = ({patientType, bookingStateSetter, modalStateSetter,
                                                }: IGuardianSexRadioInputSwitcher) => {
  switch (patientType) {
    case 'male':
      bookingStateSetter(prevState => ({
        ...prevState,
        has_oxygen: false,
        to_sex: 'MALE',
      }));
      modalStateSetter(prevState => ({
        ...prevState,
        guardianSexTypeId: patientType,
      }));
      break;
    case 'male_o2':
      bookingStateSetter(prevState => ({
        ...prevState,
        has_oxygen: true,
        to_sex: 'MALE'
      }));
      modalStateSetter(prevState => ({
        ...prevState,
        guardianSexTypeId: patientType,
      }));
      break;
    case 'female':
      bookingStateSetter(prevState => ({
        ...prevState,
        has_oxygen: false,
        to_sex: 'FEMALE',
      }));
      modalStateSetter(prevState => ({
        ...prevState,
        guardianSexTypeId: patientType,
      }));
      break;
    case 'female_o2':
      bookingStateSetter(prevState => ({
        ...prevState,
        has_oxygen: true,
        to_sex: 'FEMALE'
      }));
      modalStateSetter(prevState => ({
        ...prevState,
        guardianSexTypeId: patientType,
      }));
      break;
    default :
      break;
  }
};

export const guardianBunkRadioInputSwitcher = ({patientType, bookingStateSetter, modalStateSetter,
                                              }: IGuardianSexRadioInputSwitcher) => {
  switch (patientType) {
    case 'male':
      bookingStateSetter(prevState => ({
        ...prevState,
        from_sex: 'MALE'
      }));
      modalStateSetter(prevState => ({
        ...prevState,
        guardianBunkTypeId: patientType,
      }));
      break;
    case 'female':
      bookingStateSetter(prevState => ({
        ...prevState,
        from_sex: 'FEMALE'
      }));
      modalStateSetter(prevState => ({
        ...prevState,
        guardianBunkTypeId: patientType,
      }));
      break;
    default :
      break;
  }
};