import React, { useEffect, useState } from 'react';

import { TableAmbulance } from './components/TableAmbulance/TableAmbulance';
import { ModalWindowsDispatcherPageBooking } from './components/ModalBunkBooking/ModalBunkBooking';
import { ModalDeleteBunkBooking } from './components/ModalDeleteBunkBooking/ModalDeleteBunkBooking';
import { Route, Switch, useHistory } from 'react-router-dom';

import { Layout } from 'antd';
import { Button } from '../../Components/Button/Button';
import { covidApi } from '../../api/covidApi/covidApi';
import { Routes } from '../../routes/routes';
import { BookingList } from '../BookingList/BookingList';
import { Hospital } from '../../api/covidApi/model/Hospital.model';
import useInterval from '@use-it/interval';

const { Footer, Content } = Layout;

export const Ambulance = React.memo(() => {
    const [modalBookingVisible, setModalBookingVisible] = useState<boolean>(false);
    const [modalRemoveBookingVisible, setModalRemoveBookingVisible] = useState<boolean>(false);

    const [hospitals, setHospitals] = useState<Hospital[]>([]);
    const [selectedHospital, setSelectedHospital] = useState<Hospital>();

    const history = useHistory();

    const uploadHospitals = async () => {
      const result = await covidApi.getHospitals();
      setHospitals(result);
    };

    const invokeModalWithHospital = (selected: Hospital) => {
      setSelectedHospital(selected);
      setModalBookingVisible(true);
    };

    const cleanupSelectedHospitalOnModalClose = () => {
      setSelectedHospital(undefined);
    };

    const goToBookingList = () => {
      history.push(Routes.ROUTE_APP + Routes.ROUTE_AMBULANCE + Routes.ROUTE_BOOKING_LIST);
    };

    useEffect(() => {
      uploadHospitals();
    }, []);

    useInterval(() => {
      uploadHospitals();
    }, 10000);

    return (
      <Switch>
        <Route exact path={Routes.ROUTE_APP + Routes.ROUTE_AMBULANCE + Routes.ROUTE_BOOKING_LIST}
        >
          <BookingList backUrl={Routes.ROUTE_APP + Routes.ROUTE_AMBULANCE} />
        </Route>
        <>
          <Content className="tableContent">
            <TableAmbulance hospitals={hospitals} invokeModalWithHospital={invokeModalWithHospital} />
          </Content>
          <Footer className="tableFooter">
            <Button className="footerButton" onClick={() => setModalBookingVisible(true)}
                    disabled={!hospitals?.length}>Забронировать</Button>
            <Button className="footerButton" onClick={() => setModalRemoveBookingVisible(true)}>Снять
              бронирование</Button>
            <Button className="footerButton" onClick={goToBookingList}>Активные брони</Button>
          </Footer>
          {modalBookingVisible &&
          <ModalWindowsDispatcherPageBooking
            uploadHospitals = {uploadHospitals}
            hospitals={hospitals}
            setIsModalVisible={setModalBookingVisible}
            selectedHospitalId={selectedHospital?.id}
            cleanupSelectedHospitalOnModalClose={cleanupSelectedHospitalOnModalClose}
          />}
          {modalRemoveBookingVisible &&
          <ModalDeleteBunkBooking
            uploadHospitals = {uploadHospitals}
            setIsModalVisible={setModalRemoveBookingVisible}
          />}
        </>
      </Switch>
    );
  }
);
export default Ambulance;