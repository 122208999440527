//@ts-ignore
import DataSet from '@antv/data-set';
import { Chart } from '@antv/g2';
import moment from 'moment';
import { HospitalStat, ChartKeys, ChartData, StatFilters } from '../api/covidApi/model/Hospital.model';
moment.locale('ru',{
  months:['Января','Февраля','Марта','Апреля','Мая','Июня','Июля','Августа','Сентября','Октября','Ноября','Декабря']
})

class MainStatChartService {
  containerId: string;
  data: Array<HospitalStat>;
  chart: any;
  chartData: Array<ChartData> = [];
  weekDays: any;
  chartDataLimit = 7*24;

  dateFormatForFilter = 'YYYY-MM-DD HH:mm';
  dateFormatForDisplay = 'Do MMMM YYг. HH:mm';

  colors: any = {
    all: '#add8e6',
    busy: '#FB9081',
    free: '#85DB4C',
  };

  constructor(id: string, data: any) {
    this.containerId = id;
    this.data = data;
    this.initDateData();
    this.init();
  }

  initDateData = () => {
    this.chartData = [];
    const { data } = this;

    for (let i = 0; i < this.chartDataLimit; i++) {
      const dateForDisplay = moment().startOf('hour').subtract(i, 'hours').format(this.dateFormatForDisplay);
      const dateForFilter = moment().startOf('hour').subtract(i, 'hours').format(this.dateFormatForFilter);
      const filteredByDays = data.filter((i: HospitalStat) =>
        moment(dateForFilter).isSame(moment(i.datetime).format(this.dateFormatForFilter), 'hours')
      );
      let all = this.getAllBeds(filteredByDays);
      let busy = this.getBusyBeds(filteredByDays);
      let free = this.getFreeBeds(filteredByDays);

      this.chartData.unshift({
        day: dateForDisplay,
        [ChartKeys.All]: all || 0,
        [ChartKeys.Busy]: busy || 0,
        [ChartKeys.Free]: free || 0,
      });
    }
  };

  getAllBeds = (data: Array<HospitalStat>) => {
    return this.filterBedsByKeyWord(
      [StatFilters.BusyBunksCount, StatFilters.FreeBunksCount, StatFilters.ReservedBunksCount],
      data
    );
  };

  getBusyBeds = (data: Array<HospitalStat>) => {
    return this.filterBedsByKeyWord(StatFilters.BusyBunksCount, data);
  };

  getFreeBeds = (data: Array<HospitalStat>) => {
    return this.filterBedsByKeyWord(StatFilters.FreeBunksCount, data);
  };

  private filterBedsByKeyWord = (keyWords: StatFilters | Array<StatFilters>, data: Array<HospitalStat>) => {
    let count = 0;
    const filterKey = Array.isArray(keyWords) ? keyWords : [keyWords];

    data.forEach((stat: HospitalStat) => {
      Object.keys(stat).forEach((key: string) => {
        if (filterKey.find((word: any) => key.includes(word)) && typeof stat[key] === 'number') {
          count += stat[key];
        }
      });
    });
    return count;
  };

  init = () => {
    const dv = new DataSet.DataView().source(this.chartData);
    dv.transform({
      type: 'fold',
      fields: ['всего коек', 'занято', 'свободно'],
      key: 'type',
      value: 'value',
    });

    this.chart = new Chart({
      container: this.containerId,
      autoFit: true,
      height: 500,
    });
    this.chart.data(dv.rows);
    this.chart.scale(ChartKeys.Hour, {
      range: [0, 1],
    });
    this.chart.scale('value', {
      nice: true,
    });
    this.chart.tooltip({
      shared: true,
      showCrosshairs: true,
    });
    const {
      colors: { all, busy, free },
    } = this;
    this.chart.area().position(`${ChartKeys.Day}*value`).color('type', [all, busy, free]);
    this.chart.line().position(`${ChartKeys.Day}*value`).color('type', [all, busy, free]);
  };

  render = () => {
    this.chart.render();
  };

  clear = () => {
    this.chart.destroy();
  };
}

export default MainStatChartService;
