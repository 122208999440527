import React, { useContext } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import { Guest } from './pages/Guest/Guest';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Routes } from './routes/routes';
import { Application } from './pages/App/Application';
import { AuthContext, AuthProvider } from './providers/AuthProvider';
import { LoadingProvider } from './providers/LoaderProvider';

const App = () => {
  const { authState } = useContext(AuthContext)
  const history = useHistory();
  if (!authState.username) {
    history.push(Routes.ROUTE_GUEST)
  }
  return (
    <LoadingProvider>
      <AuthProvider>
        <div>
          <Switch>
            <Route path={Routes.ROUTE_GUEST} component={Guest} />
            <Route path={Routes.ROUTE_APP} component={Application} />
            <Redirect exact from="/password_recovery/:uid/:token"
                      to={`${Routes.ROUTE_GUEST}/password_recovery/:uid/:token`} />
            <Redirect exact from={'/'} to={Routes.ROUTE_GUEST} />
            <Redirect exact from={''} to={Routes.ROUTE_GUEST} />
          </Switch>
        </div>
      </AuthProvider>
    </LoadingProvider>
  );
};

export default App;