import React, { useMemo } from 'react';
import { Button as AntButton, Table } from 'antd';
import { TableAmbulanceProps } from './model/TableAmbulance.model';
import { Hospital } from '../../../../api/covidApi/model/Hospital.model';
import { ColumnsType } from 'antd/es/table';

const RENDER_WITH_BACKGROUND = (value: string | number) => ({
    children: <span style={{
        color: (typeof value === 'string' ? parseInt(value) : value) <= 0 ? '#fc4e4e' : '#27AE60'
    }}>{value}</span>,
    props: {
        style: {
            textAlign: 'center'
        }
    }
});

export const TableAmbulance = ({ hospitals, invokeModalWithHospital }: TableAmbulanceProps) => {

    const columns: ColumnsType<Hospital> = useMemo(
      () => ([
          {
              title: 'Название учреждения',
              render: (record: Hospital) => record.name,
              key: 'name',
              fixed: 'left',
              width: 200
          },
          {
              title: 'Тип',
              render: (record: Hospital) => record.hospital_type,
              key: 'hospital_type'
          },
          {
              title: 'Адрес',
              render: (record: Hospital) => record.address,
              key: 'address',
              width: 200
          },
          {
              title: 'Телефон приемной',
              render: (record: Hospital) => record.phone,
              key: 'address',
              width: 200
          },
          {
              title: 'Забронировать',
              render: (record: Hospital) => (<AntButton size={'middle'} type={'primary'}
                                                        onClick={() => invokeModalWithHospital(record)}>Забронировать</AntButton>),
              key: 'name',
              width: 150
          },
          {
              title: 'Свободно',
              children: [
                  {
                      title: 'М ',
                      render: (text, record: Hospital) => RENDER_WITH_BACKGROUND(record.count_male_free),
                      key: 'count_male_free',
                      sorter: (a, b) => a.count_male_free - b.count_male_free,
                      sortDirections: ['descend', 'ascend']
                  },
                  {
                      title: 'М O2',
                      render: (text, record: Hospital) => RENDER_WITH_BACKGROUND(record.count_male_o2_free),
                      key: 'count_male_o2_free',
                      sorter: (a, b) => a.count_male_o2_free - b.count_male_o2_free,
                      sortDirections: ['descend', 'ascend']
                  },
                  {
                      title: 'Ж',
                      render: (text, record: Hospital) => RENDER_WITH_BACKGROUND(record.count_female_free),
                      sorter: (a, b) => a.count_female_free - b.count_female_free,
                      sortDirections: ['descend', 'ascend'],
                      key: 'count_female_free'
                  },
                  {
                      title: 'Ж О2',
                      render: (text, record: Hospital) => RENDER_WITH_BACKGROUND(record.count_female_o2_free),
                      sorter: (a, b) => a.count_female_o2_free - b.count_female_o2_free,
                      sortDirections: ['descend', 'ascend'],
                      key: 'count_female_o2_free'
                  },
                  {
                      title: 'Д',
                      render: (text, record: Hospital) => RENDER_WITH_BACKGROUND(record.count_children_free),
                      sorter: (a, b) => a.count_children_free - b.count_children_free,
                      sortDirections: ['descend', 'ascend'],
                      key: 'count_children_free'
                  },
                  {
                      title: 'Всего',
                      sorter: (a, b) =>
                        a.count_male_free + a.count_male_o2_free + a.count_female_free + a.count_female_o2_free
                        -
                        b.count_male_free + b.count_male_o2_free + b.count_female_free + b.count_female_o2_free,
                      sortDirections: ['descend', 'ascend'],
                      render: (text, record: Hospital) => RENDER_WITH_BACKGROUND((record.count_male_free ?? 0)
                        + (record.count_male_o2_free ?? 0) + (record.count_female_free ?? 0)
                        + (record.count_female_o2_free ?? 0) + (record.count_children_free ?? 0)),
                      key: 'free_summary'
                  }
              ]
          },
          {
              title: 'Бронь',
              children: [
                  {
                      title: 'М ',
                      render: (record: Hospital) => record.count_male_reservations ?? '',
                      key: 'count_male_reservations'
                  },
                  {
                      title: 'М O2',
                      render: (record: Hospital) => record.count_male_o2_reservations ?? '',
                      key: 'count_male_o2_reservations'
                  },
                  {
                      title: 'Ж',
                      render: (record: Hospital) => record.count_female_reservations ?? '',
                      key: 'count_female_reservations'
                  },
                  {
                      title: 'Ж О2',
                      render: (record: Hospital) => record.count_female_o2_reservations ?? '',
                      key: 'count_female_o2_reservations'
                  },
                  {
                      title: 'Д',
                      render: (record: Hospital) => record.count_children_reservations ?? '',
                      key: 'count_children_reservations'
                  },
                  {
                      title: 'Всего',
                      render: (record: Hospital) => (record.count_male_reservations ?? 0) + (record.count_male_o2_reservations ?? 0)
                        + (record.count_female_reservations ?? 0) + (record.count_female_o2_reservations ?? 0)
                        + (record.count_children_reservations ?? 0),
                      key: 'reservations_summary'
                  }
              ]
          },
          {
              title: 'Занято',
              children: [
                  {
                      title: 'М ',
                      render: (record: Hospital) => record.count_male_busy ?? '',
                      key: 'count_male_busy'
                  },
                  {
                      title: 'М O2',
                      render: (record: Hospital) => record.count_male_o2_busy ?? '',
                      key: 'count_male_o2_busy'
                  },
                  {
                      title: 'Ж',
                      render: (record: Hospital) => record.count_female_busy ?? '',
                      key: 'count_female_busy'
                  },
                  {
                      title: 'Ж О2',
                      render: (record: Hospital) => record.count_female_o2_busy ?? '',
                      key: 'count_female_o2_busy'
                  },
                  {
                      title: 'Д',
                      render: (record: Hospital) => record.count_children_busy ?? '',
                      key: 'count_children_busy'
                  },
                  {
                      title: 'Всего',
                      render: (record: Hospital) => (record.count_male_busy ?? 0) + (record.count_male_o2_busy ?? 0)
                        + (record.count_female_busy ?? 0) + (record.count_female_o2_busy ?? 0) + ( record.count_children_busy ?? 0),
                      key: 'busy_summary'
                  }
              ]
          }
      ]),
      [invokeModalWithHospital]);

    return (
      <Table
        columns={columns}
        dataSource={hospitals}
        pagination={false}
        sticky
        bordered
        size="middle"
        locale={{
            triggerDesc: 'Щелкните, чтобы сортировать по уменьшению',
            triggerAsc: 'Щелкните, чтобы сортировать по возрастанию',
            cancelSort: 'Щелкните, убрать сортировку'
        }}
      />
    );
};