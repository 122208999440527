import { Button } from '../../Components/Button/Button';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { TableBookingList } from './components/TableBookingList/TableBookingList';
import { Layout, Modal } from 'antd';
import { covidApi } from '../../api/covidApi/covidApi';
import { BookingListItem } from './model/BookingListItem.model';
import { ModalDeleteBooking } from './components/ModalDeleteBookingVisible/ModalDeleteBooking';
import { errorNotification } from '../../Components/Notification/setOfNotifications';
import useInterval from '@use-it/interval';
import { BookingListProps } from './model/BookingList.model';

const { Footer, Content } = Layout;
const confirm = Modal.confirm;

export const BookingList = ({ backUrl }: BookingListProps) => {
  const [bookingList, setBookingList] = useState<BookingListItem[]>([]);
  const [modalDeleteBookingVisible, setModalDeleteBookingVisible] = useState<boolean>(false);
  const history = useHistory();

  const uploadBookingList = () => {
    try {
      covidApi.getBookList()
        .then(result => setBookingList(result));
    } catch (e) {
      // @ts-ignore
      errorNotification(e.response);
    }
  };

  useEffect(() => {
    uploadBookingList();
  }, []);

  useInterval(() => {
    uploadBookingList();
  }, 10000);

  const handleGoToAmbulance = () => {
    history.push(backUrl);
  };

  const deleteBookingRecord = (item: BookingListItem) => {
    confirm({
      title: 'Вы уверены что следует удалить бронь?',
      content: 'Бронь будет отменена, действие отменить невозможно',
      okText: 'Удалить бронь',
      cancelText: 'Выйти без удаления',
      onOk() {
        covidApi.removeBookingRecord(item.reservation_id)
          .then(() => {
              uploadBookingList();
            }
          );
      },
      onCancel() {
      }
    });
  };

  return (
    <>
      <Content className="tableContent">
        <TableBookingList bookingList={bookingList} deleteBookingRecord={deleteBookingRecord} />
      </Content>
      <Footer className="tableFooter">
        <Button className="footerButton" onClick={handleGoToAmbulance}>Вернуться</Button>
        <Button className="footerButton" onClick={() => setModalDeleteBookingVisible(true)}>
          Снять бронирование
        </Button>
      </Footer>
      {modalDeleteBookingVisible &&
      <ModalDeleteBooking
        setIsModalVisible={setModalDeleteBookingVisible}
        bookingList={bookingList}
        reloadBookingList={uploadBookingList}
      />}
    </>
  );
};