import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../Components/Button/Button';

import { covidApi } from '../../api/covidApi/covidApi';
import { ModalEditDepartment } from './components/ModalEditDepartment/ModalEditDepartment';
import { ModalTransferDepartment } from './components/ModalTransferDepartment/ModalTransferDepartment';
import { Route, Switch } from 'react-router-dom';
import { Routes } from '../../routes/routes';
import { BookingList } from '../BookingList/BookingList';
import { Button as AntButton, Card, Divider, Layout, Progress } from 'antd';
// @ts-ignore
import styles from './styles/HospitalDepartment.module.css';
import { errorNotification } from '../../Components/Notification/setOfNotifications';
import useInterval from '@use-it/interval';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { HospitalDepartmentProps } from './model/HospitalDepartment.model';
import { DepartmentInfo } from '../../api/covidApi/model/DepartmentInfo.model';
import { ModalAllInformation } from './components/ModalAllInformation/ModalAllInformation';
import { ModalDischarge } from './components/ModalDischarge/ModalDischarge';

const { Footer, Content } = Layout;

const HospitalDepartment = React.memo(({ selectedDepartment }: HospitalDepartmentProps) => {
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [showTransfer, setShowTransfer] = useState<boolean>(false);
    const [showAllInformation, setShowAllInformation] = useState<boolean>(false);
    const [showDischarge, setShowDischarge] = useState<boolean>(false);
    const [department, setDepartment] = useState<DepartmentInfo | undefined>(undefined);
    const [allDepartments, setAllDepartments] = useState<DepartmentInfo[]>([]);

    const fetchAllDepartments = useCallback(() => {
      try {
        covidApi.getDepartments()
          .then((result) => {
            setAllDepartments(result);
          });
      } catch (e) {
        // @ts-ignore
        errorNotification(e.response, 'Ошибка при загрузке данных отделений');
      }
    }, []);

    const fetchDepartment = useCallback((selectedDepartmentID?:number) => {
      let idForFetchDepartment = selectedDepartmentID;
      if (!selectedDepartmentID && selectedDepartment?.id) { idForFetchDepartment = selectedDepartment?.id}
      if (idForFetchDepartment) {
        try {
          covidApi.getDepartmentById(idForFetchDepartment)
            .then((result) => {
              if (result) {
                setDepartment(result);
              }

            }).then((response) => {
            return response;
          });
        } catch (e) {
          // @ts-ignore
          errorNotification(e.response, 'Ошибка при загрузке данных отделения');
        }
      } else {
        errorNotification(null, 'Выбранное отделение имеет некорректный идентификатор');
      }
    }, [selectedDepartment?.id]);

    const postDataDepartments = useCallback((data) => {
      if (selectedDepartment) {
        covidApi.changeBunksInDepartment(data, selectedDepartment.id).then(() => {
          fetchDepartment(selectedDepartment.id);
        });
      }
    }, [fetchDepartment, selectedDepartment]);

    const showEditVisible = useCallback(() => {
      setShowEdit(true);
    }, []);

    const showTransferVisible = useCallback(() => {
      fetchAllDepartments();
      setShowTransfer(true);
    }, [fetchAllDepartments]);

    const showInformation = useCallback(() => {
      setShowAllInformation(true);
    }, []);

    const showDiscard = useCallback(() => {
      fetchAllDepartments();
      setShowDischarge(true);
    }, [fetchAllDepartments]);

    useEffect(() => {
      if (selectedDepartment) {
        fetchDepartment(selectedDepartment.id);
        fetchAllDepartments();
      }
    }, [fetchAllDepartments, fetchDepartment, selectedDepartment]);

    useInterval(() => {
      if (selectedDepartment) {
        fetchDepartment(selectedDepartment.id);
      }
    }, 10000);

    const totalMaleBunks = useMemo(() => {
      if (department) {
        return department.count_male_busy + department.count_male_free;
      }
      return 0;
    }, [department]);

    const malePercentage = useMemo(() => {
      if (department) {
        const totalCount = totalMaleBunks || 1;
        return (department?.count_male_busy / totalCount * 100).toFixed(1);
      }
      return 0;
    }, [department, totalMaleBunks]);

    const totalMaleO2Bunks = useMemo(() => {
      if (department) {
        return department?.count_male_o2_busy + department?.count_male_o2_free;
      }
      return 0;
    }, [department]);

    const maleO2Percentage = useMemo(() => {
      if (department) {
        const totalCount = totalMaleO2Bunks || 1;
        return (department?.count_male_o2_busy / totalCount * 100).toFixed(1);
      }
      return 0;
    }, [department, totalMaleO2Bunks]);

    const totalFemaleBunks = useMemo(() => {
      if (department) {
        return department?.count_female_busy + department?.count_female_free;
      }
      return 0;
    }, [department]);

    const femalePercentage = useMemo(() => {
      if (department) {
        const totalCount = totalFemaleBunks || 1;
        return (department?.count_female_busy / totalCount * 100).toFixed(1);
      }
      return 0;
    }, [department, totalFemaleBunks]);

    const totalFemaleO2Bunks = useMemo(() => {
      if (department) {
        return department?.count_female_o2_busy + department?.count_female_o2_free;
      }
      return 0;
    }, [department]);

    const femaleO2Percentage = useMemo(() => {
      if (department) {
        const totalCount = totalFemaleO2Bunks || 1;
        return (department?.count_female_o2_busy / totalCount * 100).toFixed(1);
      }
      return 0;
    }, [department, totalFemaleO2Bunks]);

    const totalChildrenBunks = useMemo(() => {
      if (department) {
        return department.count_children_busy + department.count_children_free;
      }
      return 0;
    }, [department]);

    const childrenPercentage = useMemo(() => {
      if (department) {
        const totalCount = totalChildrenBunks || 1;
        return (department?.count_children_busy / totalCount * 100).toFixed(1);
      }
      return 0;
    }, [department, totalChildrenBunks]);

    const handleAddOneBunk = useCallback((sex, has_oxygen, count_busy, count_free) => {
      if (selectedDepartment) {
        covidApi.addBunksToDepartment(selectedDepartment.id, sex, has_oxygen, count_busy, count_free)
          .then(() => fetchDepartment(selectedDepartment.id));
      }
    }, [fetchDepartment, selectedDepartment]);

    const handleRemoveOneBunk = useCallback((sex, has_oxygen, count_busy, count_free) => {
      if (selectedDepartment) {
        covidApi.deleteOneBunk(selectedDepartment.id, sex, has_oxygen, count_busy, count_free)
          .then(() => fetchDepartment(selectedDepartment.id));
      }
    }, [fetchDepartment, selectedDepartment]);

    return (
      <Switch>
        <Route exact path={Routes.ROUTE_APP + Routes.ROUTE_AMBULANCE + Routes.ROUTE_BOOKING_LIST}
        >
          <BookingList backUrl={Routes.ROUTE_APP + Routes.ROUTE_AMBULANCE} />
        </Route>
        <>
          <Content className="tableContent">
            <div className={styles.cardsContainer}>

              <Card className={styles.card} title="Койки мужские без кислорода" bordered style={{ width: 400 }}>
                <div className={styles.rowInfo}>
                  <AntButton type="primary" shape="circle" icon={<MinusOutlined />}
                             onClick={() => handleRemoveOneBunk('MALE', false, 1, 0)} />
                  <span className={styles.text}>Занято: {department?.count_male_busy}</span>
                  <AntButton type="primary" shape="circle" icon={<PlusOutlined />}
                             onClick={() => handleAddOneBunk('MALE', false, 1, 0)} />
                </div>
                <Divider plain orientation={'center'} />
                <div className={styles.rowInfo}>
                  <AntButton type="primary" shape="circle" icon={<MinusOutlined />}
                             onClick={() => handleRemoveOneBunk('MALE', false, 0, 1)} />
                  <span
                    className={styles.text}>Свободно: {department?.count_male_free}</span>
                  <AntButton type="primary" shape="circle" icon={<PlusOutlined />}
                             onClick={() => handleAddOneBunk('MALE', false, 0, 1)} />
                </div>
                <Divider plain orientation={'center'} />
                <p>Всего: {totalMaleBunks}</p>
                <Progress percent={+malePercentage}
                          status={+malePercentage === 100 ? 'exception' : 'active'} />
              </Card>

              <Card className={styles.card} title="Койки мужские c кислородом" bordered style={{ width: 400 }}>
                <div className={styles.rowInfo}>
                  <AntButton type="primary" shape="circle" icon={<MinusOutlined />}
                             onClick={() => handleRemoveOneBunk('MALE', true, 1, 0)} />
                  <span
                    className={styles.text}>Занято: {department?.count_male_o2_busy}</span>
                  <AntButton type="primary" shape="circle" icon={<PlusOutlined />}
                             onClick={() => handleAddOneBunk('MALE', true, 1, 0)} />
                </div>
                <Divider plain orientation={'center'} />
                <div className={styles.rowInfo}>
                  <AntButton type="primary" shape="circle" icon={<MinusOutlined />}
                             onClick={() => handleRemoveOneBunk('MALE', true, 0, 1)} />
                  <span
                    className={styles.text}>Свободно: {department?.count_male_o2_free}</span>
                  <AntButton type="primary" shape="circle" icon={<PlusOutlined />}
                             onClick={() => handleAddOneBunk('MALE', true, 0, 1)} />
                </div>
                <Divider plain orientation={'center'} />
                <p>Всего: {totalMaleO2Bunks}</p>
                <Progress percent={+maleO2Percentage}
                          status={+maleO2Percentage === 100 ? 'exception' : 'active'} />
              </Card>


              <Card className={styles.card} title="Койки женские без кислорода" bordered style={{ width: 400 }}>
                <div className={styles.rowInfo}>
                  <AntButton type="primary" shape="circle" icon={<MinusOutlined />}
                             onClick={() => handleRemoveOneBunk('FEMALE', false, 1, 0)} />
                  <span
                    className={styles.text}>Занято: {department?.count_female_busy}</span>
                  <AntButton type="primary" shape="circle" icon={<PlusOutlined />}
                             onClick={() => handleAddOneBunk('FEMALE', false, 1, 0)} />
                </div>
                <Divider plain orientation={'center'} />
                <div className={styles.rowInfo}>
                  <AntButton type="primary" shape="circle" icon={<MinusOutlined />}
                             onClick={() => handleRemoveOneBunk('FEMALE', false, 0, 1)} />
                  <span
                    className={styles.text}>Свободно: {department?.count_female_free}</span>
                  <AntButton type="primary" shape="circle" icon={<PlusOutlined />}
                             onClick={() => handleAddOneBunk('FEMALE', false, 0, 1)} />
                </div>
                <Divider plain orientation={'center'} />
                <p>Всего: {totalFemaleBunks}</p>
                <Progress percent={+femalePercentage}
                          status={+femalePercentage === 100 ? 'exception' : 'active'} />
              </Card>


              <Card className={styles.card} title="Койки женские c кислородом" bordered style={{ width: 400 }}>

                <div className={styles.rowInfo}>
                  <AntButton type="primary" shape="circle" icon={<MinusOutlined />}
                             onClick={() => handleRemoveOneBunk('FEMALE', true, 1, 0)} />
                  <span
                    className={styles.text}>Занято: {department?.count_female_o2_busy}</span>
                  <AntButton type="primary" shape="circle" icon={<PlusOutlined />}
                             onClick={() => handleAddOneBunk('FEMALE', true, 1, 0)} />
                </div>
                <Divider plain orientation={'center'} />
                <div className={styles.rowInfo}>
                  <AntButton type="primary" shape="circle" icon={<MinusOutlined />}
                             onClick={() => handleRemoveOneBunk('FEMALE', true, 0, 1)} />
                  <span
                    className={styles.text}>Свободно: {department?.count_female_o2_free}</span>
                  <AntButton type="primary" shape="circle" icon={<PlusOutlined />}
                             onClick={() => handleAddOneBunk('FEMALE', true, 0, 1)} />
                </div>
                <Divider plain orientation={'center'} />
                <p>Всего: {totalFemaleO2Bunks}</p>
                <Progress percent={+femaleO2Percentage}
                          status={+femaleO2Percentage === 100 ? 'exception' : 'active'} />

              </Card>

              <Card className={styles.card} title="Койки детские" bordered style={{ width: 400 }}>
                <div className={styles.rowInfo}>
                  <AntButton type="primary" shape="circle" icon={<MinusOutlined />}
                             onClick={() => handleRemoveOneBunk('CHILDREN', false, 1, 0)} />
                  <span className={styles.text}>Занято: {department?.count_children_busy}</span>
                  <AntButton type="primary" shape="circle" icon={<PlusOutlined />}
                             onClick={() => handleAddOneBunk('CHILDREN', false, 1, 0)} />
                </div>
                <Divider plain orientation={'center'} />
                <div className={styles.rowInfo}>
                  <AntButton type="primary" shape="circle" icon={<MinusOutlined />}
                             onClick={() => handleRemoveOneBunk('CHILDREN', false, 0, 1)} />
                  <span
                    className={styles.text}>Свободно: {department?.count_children_free}</span>
                  <AntButton type="primary" shape="circle" icon={<PlusOutlined />}
                             onClick={() => handleAddOneBunk('CHILDREN', false, 0, 1)} />
                </div>
                <Divider plain orientation={'center'} />
                <p>Всего: {totalChildrenBunks}</p>
                <Progress percent={+childrenPercentage}
                          status={+childrenPercentage === 100 ? 'exception' : 'active'} />
              </Card>

            </div>
          </Content>
          <Footer className="tableFooter">
            <Button className={styles.footerButton} onClick={showEditVisible}>Редактировать</Button>
            <Button className={styles.footerButton} onClick={showTransferVisible}>Перевод</Button>
            <Button className={styles.footerButton} onClick={showDiscard}>Выписать</Button>
            <Button className={styles.footerButton} onClick={showInformation}>Информация</Button>
          </Footer>
          {showEdit && department && <ModalEditDepartment
            confirmCallback={postDataDepartments}
            data={department}
            setShowEdit={setShowEdit}
            fetchDepartment={fetchDepartment}
          />}
          {showTransfer && department && <ModalTransferDepartment
            department={department}
            allDepartments={allDepartments}
            setShowTransfer={setShowTransfer}
            fetchDepartment={fetchDepartment}
          />}
          {showAllInformation && <ModalAllInformation setShowAllInformation={setShowAllInformation} />}
          {showDischarge && <ModalDischarge allDepartments={allDepartments} setShowDischarge={setShowDischarge} fetchDepartment={fetchDepartment}/>}
        </>
      </Switch>
    );
  }
);
export default HospitalDepartment;