import React, { useCallback, useContext, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
// @ts-ignore
import styles from './styles/Guest.module.css';
import { useHistory } from 'react-router-dom';
import { authApi } from '../../api/authApi';
import { Routes } from '../../routes/routes';
import { AuthContext } from '../../providers/AuthProvider';
import { Logo } from '../../Components/Icon/Logo';
import { LogoITS } from '../../Components/Icon/ITSLogo';

const { Item } = Form;

export const Guest = () => {
  const history = useHistory();
  const { setAuthState } = useContext(AuthContext);
  const onFinish = useCallback(async (formValues: any) => {
    authApi.login(formValues)
      .then(({ username }) => {
        setAuthState({
          departments: [],
          username: username ?? '',
          groups: []
        });
        username ? history.replace(Routes.ROUTE_APP) : history.replace(Routes.ROUTE_GUEST);
      });
  }, [history, setAuthState]);

  useEffect(() => {
    const { refresh, access, username } = localStorage;
    if (refresh && access && username) {
      authApi.refreshToken()
        .then(() => {
          setAuthState({
            username: username,
            groups: [],
            departments: []
          });
          history.replace(Routes.ROUTE_APP);
        });
    }
  }, [history, setAuthState]);

  // noinspection RequiredAttributes
  return (
    <>
      <div className={styles.loginFormWrapper}>
        <Form
          name="normal_login"
          className={styles.loginForm}
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
          size={'large'}
        >
          <div className={styles.loginFormTitle}>
            <h2> Вход </h2>
          </div>
          <Item className={styles.loginFormItem}
            name="username"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите ваше имя!'
              }
            ]}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Имя пользователя" />
          </Item>
          <Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите ваше пароль!'
              }
            ]}>
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Пароль"
              visibilityToggle
            />
          </Item>
          <Item>
            <Button type="primary" htmlType="submit" className={styles.loginFormButton}>
              Войти
            </Button>
            <div className={styles.infoNotification}>
              <InfoCircleOutlined className={styles.infoIcon} /> Не можете войти?
            </div>
            <div className={styles.infoNotification} style={{fontSize: '14px', margin: '0'}}>
              Свяжитесь с технической поддержкой по номеру телефона <span style={{color: '#1890FF'}}>8 (3452) 68 48 04
              доб. 7629</span>
            </div>
          </Item>
        </Form>
        <div className={styles.siteTitleContainer}>
          <Logo className={styles.logoImage}/>
          <div className={styles.logoTextContainer}>
            <p className={styles.mainLogoText}>Информационная система “Коечный фонд - бронирование”</p>
            <p className={styles.additionalLogoText}>Департамент информатизации Тюменской области</p>
          </div>
        </div>
        <div className={styles.developerLogo}>
          <LogoITS className={styles.logoImage}/>
        </div>
      </div>
    </>
  );
};