import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Radio, Select } from 'antd';
// @ts-ignore
import styles from './styles/ModalBunkBooking.module.css';
import { covidApi } from '../../../../api/covidApi/covidApi';
import { ModalBunkDeleteProps } from './model/ModalDeleteBunk.model';
import { RadioChangeEvent } from 'antd/es/radio';
import { DepartmentDeleteState } from './model/DepartmentDeleteState.model';


const {Option} = Select;

export const ModalDeleteBunk = React.memo(({setIsModalVisible, departments, uploadDepartments, uploadReservations}: ModalBunkDeleteProps) => {

    const [bookingInfo, setBookingInfo] = useState<DepartmentDeleteState>({
        sex: undefined,
        has_oxygen: undefined,
    });

    // закрытие модалки
    const handleCloseModal = useCallback(() => {
        setIsModalVisible(false);
    }, [setIsModalVisible]);

    const allKeysDefined = (object: any) => {
        return !Object.keys(object).some(key => object[key] === undefined);
    };

    const handleBookBunk = useCallback(() => {
        if (bookingInfo.department_id && bookingInfo.sex && bookingInfo.has_oxygen!==undefined){
            covidApi.dischargePatient({
                department_id: bookingInfo.department_id,
                has_oxygen: bookingInfo.has_oxygen,
                sex: bookingInfo.sex})
              .then(() => {
                  uploadDepartments()
                  uploadReservations()
                  setIsModalVisible(false);
              })
        }

    }, [bookingInfo.department_id, bookingInfo.has_oxygen, bookingInfo.sex, setIsModalVisible, uploadDepartments, uploadReservations]);


    const footerButtons = useMemo(() => {
        const bookingDisabled = !allKeysDefined(bookingInfo);
        return (
          <>
              <Button key="back" onClick={handleCloseModal}>
                  Отмена
              </Button>
              <Button type="primary" onClick={handleBookBunk} disabled={bookingDisabled}>
                  Выписать
              </Button>
          </>
        );
    }, [bookingInfo, handleBookBunk, handleCloseModal]);

    const hospitalOptions = useMemo(() => {
        return departments?.map(department => <Option value={department.id}>{department.name}</Option>);
    }, [departments]);

    const handleSelectHospital = (departmentId: number) => {
        setBookingInfo(prevState => ({
            ...prevState,
            department_id: departmentId
        }));
    };

    const bookTypeRadios = useMemo(() => {
        const selectedDepartmentInfo = departments?.find(department => department.id === bookingInfo?.department_id);
        return [
            {label: 'Муж.', value: 'male', style: {width: '20%'}, disabled: !selectedDepartmentInfo?.count_male_busy},
            {label: 'Муж. О2', value: 'male_o2', style: {width: '20%'}, disabled: !selectedDepartmentInfo?.count_male_o2_busy},
            {label: 'Жен.', value: 'female',  style: {width: '20%'}, disabled: !selectedDepartmentInfo?.count_female_busy},
            {label: 'Жен. О2', value: 'female_o2',  style: {width: '20%'}, disabled: !selectedDepartmentInfo?.count_female_o2_busy},
            {label: 'Дет.', value: 'children',  style: {width: '20%'}, disabled: !selectedDepartmentInfo?.count_children_busy},
        ];
    }, [bookingInfo?.department_id, departments]);

    const handleTypeBookChange = (event: RadioChangeEvent) => {
        const {value} = event.target;
        switch (value) {
            case 'male':
                setBookingInfo(prevState => ({
                    ...prevState,
                    has_oxygen: false,
                    sex: 'MALE'
                }));
                break;
            case 'male_o2':
                setBookingInfo(prevState => ({
                    ...prevState,
                    has_oxygen: true,
                    sex: 'MALE'
                }));
                break;
            case 'female':
                setBookingInfo(prevState => ({
                    ...prevState,
                    has_oxygen: false,
                    sex: 'FEMALE'
                }));
                break;
            case 'female_o2':
                setBookingInfo(prevState => ({
                    ...prevState,
                    has_oxygen: true,
                    sex: 'FEMALE'
                }));
                break;
            case 'children':
                setBookingInfo(prevState => ({
                    ...prevState,
                    has_oxygen: false,
                    sex: 'CHILDREN'
                }));
                break;
            default :
                break;
        }
    };

    return (
      <>
          <Modal
            width={600}
            className={styles.modalWindow}
            visible={true}
            destroyOnClose={true}
            centered
            title="Выписка"
            onOk={handleBookBunk}
            onCancel={handleCloseModal}
            footer={footerButtons}
          >
              <div className={styles.contentWrapper}>
                  <label className={styles.label}> Отделение из которого выписать:
                      <Select className={styles.select} placeholder={'Откуда'} size={'large'}
                              value={bookingInfo?.department_id}
                              onSelect={handleSelectHospital}>
                          {hospitalOptions}
                      </Select>
                  </label>
                  <label className={styles.label}> Информация о пациенте:
                      <Radio.Group
                        style = {{width: '100%', textAlign: 'center'}}
                        disabled={!bookingInfo?.department_id}
                        options={bookTypeRadios}
                        onChange={handleTypeBookChange}
                        optionType="button"
                        buttonStyle="solid"
                        size='large'
                      />
                  </label>
              </div>
          </Modal>
      </>
    );
});