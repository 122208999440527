import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Radio, Select, Switch } from 'antd';
// @ts-ignore
import styles from './styles/ModalBunkBooking.module.css';
import { covidApi } from '../../../../api/covidApi/covidApi';
import { ModalBunkBookingProps } from './model/ModalBunkBooking.model';
import { DepartmentBookingState } from './model/DepartmentBookingState.model';
import { RadioChangeEvent } from 'antd/es/radio';
import Search from 'antd/lib/input/Search';
import { errorNotification } from '../../../../Components/Notification/setOfNotifications';
import { LocalBookingInfoState } from './model/LocalBookingInfoState.model';
import { BookBunkRequest } from '../../../../api/covidApi/model/BookBunkRequest.model';
import { BookType } from './model/BookType.model';
import { LocaleNotificationSetter } from './utils/LocaleNotificationSetter';
import {
  guardianBunkRadioInputSwitcher,
  guardianSexRadioInputSwitcher,
  patientTypeRadioInputSwitcher,
  switcherTypes
} from './utils/patientTypeRadioInputSwitcher';
import { bookingSearcher } from './utils/bookingSearcher';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { IGuardianState } from './model/GuardianBookingState.model';
import { LocalNotification } from './model/LocalNotification.model';

const { Option } = Select;

export const ModalBunkBooking = React.memo(({
                                              cleanupSelectedHospitalOnModalClose, setIsModalVisible,
                                              selectedDepartmentId, departments, uploadDepartments, uploadReservations
                                            }: ModalBunkBookingProps) => {

  const bookingInfoInitialValues: DepartmentBookingState = useMemo(() => ({
    department_id: selectedDepartmentId,
    sex: undefined,
    has_oxygen: undefined,
    reservation_id: undefined,
    guardian_reservation_id: undefined
  }), [selectedDepartmentId]);

  const [bookingInfo, setBookingInfo] = useState<DepartmentBookingState>(bookingInfoInitialValues);

  const initialLocaleBookingInfo: LocalBookingInfoState = useMemo(() => ({
    typeOfBookTransaction: 'withBooking',
    localeNotification: undefined,
    reservationInfo: {},
    radioIdentification: undefined,
    guardianSexTypeId: undefined,
    guardianBunkTypeId: undefined
  }), []);

  const [localeBookingInfo, setLocaleBookingInfo] = useState<LocalBookingInfoState>(initialLocaleBookingInfo);

  /** стейт для опекуна, используется в случае если тип пациента - ребёнок */
  const initialGuardianBookingInfo: IGuardianState = useMemo(() => ({
    from_sex: undefined,
    to_sex: undefined,
    reservation_id: undefined,
    from_department_id: undefined,
    has_oxygen: undefined
  }), []);

  const [guardianBookingInfo, setGuardianBookingInfo] = useState<IGuardianState>(initialGuardianBookingInfo);

  // показатель госпитализировать ли ребёнка - влияет на формирование конечного запроса
  const [isChildHospitalizing, setIsChildHospitalizing] = useState<boolean>(true);

  // показатель госпитализировать ли опекуна - влияет на формирование конечного запроса и отрисовку полей инфы по опекуну
  const [isGuardianHospitalizing, setGuardianHospitalizing] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (selectedDepartmentId) {
        cleanupSelectedHospitalOnModalClose();
      }
    };
  }, [cleanupSelectedHospitalOnModalClose, selectedDepartmentId]);

  // закрытие модалки
  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  // проверка и отправка финального объекта
  const handleBookBunk = useCallback(() => {

    const requestFormater = () => {
      if (bookingInfo.sex !== 'CHILDREN' || (isChildHospitalizing && !isGuardianHospitalizing)) {
        // если передаётся reservation_id, то бронь с этим id снимается, если нет - просто занимается койка
        return {
          department_id: bookingInfo.department_id,
          sex: bookingInfo.sex,
          has_oxygen: bookingInfo.has_oxygen,
          reservation_id: bookingInfo.reservation_id,
        };
      } else {
        if (!isChildHospitalizing && isGuardianHospitalizing) {
          return {
            department_id: bookingInfo.department_id,
            sex: guardianBookingInfo.to_sex,
            has_oxygen: guardianBookingInfo.has_oxygen,
            reservation_id: guardianBookingInfo.reservation_id,
          };
        } else if (isChildHospitalizing && isGuardianHospitalizing) {
          return {
            department_id: bookingInfo.department_id,
            sex: bookingInfo.sex,
            has_oxygen: bookingInfo.has_oxygen,
            reservation_id: bookingInfo.reservation_id,
            guardian: {
              from_department_id: guardianBookingInfo.from_department_id,
              to_sex: guardianBookingInfo.to_sex,
              from_sex: guardianBookingInfo.from_sex,
              reservation_id: guardianBookingInfo.reservation_id,
            }
          }
        } else if (isChildHospitalizing && !isGuardianHospitalizing) {
          return {
            department_id: bookingInfo.department_id,
            sex: bookingInfo.sex,
            has_oxygen: bookingInfo.has_oxygen,
            reservation_id: bookingInfo.reservation_id,
          }
        }
      }
      // TODO Error присобачить
    };

    const adapted = requestFormater() as BookBunkRequest;
    covidApi.setBunk(adapted)
      .then((response) => {
        if (response) {
          uploadDepartments();
          uploadReservations();
          setIsModalVisible(false);
        }
      });

  }, [bookingInfo.department_id, bookingInfo.has_oxygen, bookingInfo.reservation_id, bookingInfo.sex, guardianBookingInfo.from_department_id,
    guardianBookingInfo.from_sex, guardianBookingInfo.has_oxygen, guardianBookingInfo.reservation_id, guardianBookingInfo.to_sex,
    isChildHospitalizing, isGuardianHospitalizing, setIsModalVisible, uploadDepartments, uploadReservations]);

  const allKeysDefined = useCallback((sendObject: any) => {
    const deepCheckValue = 'reservation_id';

    const isKeyComplete = (key: string, checkObj: any) => (
      (key.includes(deepCheckValue) && !checkObj[key as keyof typeof checkObj])
      || checkObj[key as keyof typeof checkObj] === undefined
    );

    if (localeBookingInfo.typeOfBookTransaction === 'withBooking') {
      if (bookingInfo.sex === 'CHILDREN') {
        if (!isChildHospitalizing && !isGuardianHospitalizing) return false;
        if (!isGuardianHospitalizing) {
          return !Object.keys(sendObject).some(key => key !== 'guardian_reservation_id' && isKeyComplete(key, sendObject));
        } else if (isGuardianHospitalizing && isChildHospitalizing) {
          return !Object.keys(sendObject).some(key => key !== 'guardian_reservation_id' && isKeyComplete(key, sendObject))
            && !Object.keys(guardianBookingInfo).some(key =>
              key !== 'has_oxygen' && key !== 'reservation_id' && isKeyComplete(key, guardianBookingInfo));
        } else if (isGuardianHospitalizing && !isChildHospitalizing) {
          return !Object.keys(sendObject).some(key => key !== 'guardian_reservation_id' && isKeyComplete(key, sendObject)) &&
            !Object.keys(guardianBookingInfo).some(key => key !== 'from_sex' && key !== 'reservation_id'
              && isKeyComplete(key, guardianBookingInfo));
        }
      } else {
        return !Object.keys(sendObject).some(key => key !== 'guardian_reservation_id' && isKeyComplete(key, sendObject));
      }
    } else if (localeBookingInfo.typeOfBookTransaction === 'withoutBooking') {
      return !Object.keys(sendObject).some(key => key !== 'reservation_id' && key !== 'guardian_reservation_id' && isKeyComplete(key, sendObject));
    }
  }, [bookingInfo.sex, guardianBookingInfo, isChildHospitalizing, isGuardianHospitalizing, localeBookingInfo.typeOfBookTransaction]);

  const footerButtons = useMemo(() => {
    const checkedKeys = allKeysDefined(bookingInfo);
    const bookingDisabled = !checkedKeys;

    return (
      <>
        <Button key='back' onClick={handleCloseModal}>
          Отмена
        </Button>
        <Button type='primary' onClick={handleBookBunk} disabled={bookingDisabled}>
          Занять
        </Button>
      </>
    );
  }, [allKeysDefined, bookingInfo, handleBookBunk, handleCloseModal]);

  const hospitalOptions = useMemo(() => {
    return departments?.map(department => <Option value={department?.id}>{department.name}</Option>);
  }, [departments]);

  const handleTypeBookTransactionChange = useCallback((value: BookType) => {
    setBookingInfo(bookingInfoInitialValues);
    setGuardianBookingInfo(initialGuardianBookingInfo);
    setLocaleBookingInfo(prevState => ({
      ...prevState,
      typeOfBookTransaction: value,
      localeNotification: initialLocaleBookingInfo.localeNotification,
      reservationInfo: initialLocaleBookingInfo.reservationInfo,
      radioIdentification: initialLocaleBookingInfo.radioIdentification
    }));
  }, [bookingInfoInitialValues, initialGuardianBookingInfo, initialLocaleBookingInfo.localeNotification,
    initialLocaleBookingInfo.radioIdentification, initialLocaleBookingInfo.reservationInfo]);

  const handleSelectHospitalDepartment = useCallback((department_id: number) => {
    setBookingInfo(prevState => ({
      ...prevState,
      department_id
    }));
    if (localeBookingInfo.radioIdentification || localeBookingInfo.localeNotification || localeBookingInfo.reservationInfo) {
      // setBookingInfo(bookingInfoInitialValues);
      // setLocaleBookingInfo(initialLocaleBookingInfo);
      // setGuardianBookingInfo(initialGuardianBookingInfo);
      setBookingInfo(prevState => ({
        ...prevState,
        sex: bookingInfoInitialValues.sex,
        has_oxygen: bookingInfoInitialValues.has_oxygen,
        guardian_reservation_id: bookingInfoInitialValues.guardian_reservation_id
      }));
      setLocaleBookingInfo(prevState => ({
        ...prevState,
        localeNotification: initialLocaleBookingInfo.localeNotification,
        radioIdentification: initialLocaleBookingInfo.radioIdentification,
        reservationInfo: initialLocaleBookingInfo.reservationInfo
      }));
    }
  }, [bookingInfoInitialValues, initialLocaleBookingInfo, localeBookingInfo.localeNotification, localeBookingInfo.radioIdentification, localeBookingInfo.reservationInfo]);

  const handleChangeBookingDocument = useCallback((event: React.ChangeEvent<{ value: string }>) => {
    const reservation_id = event.target.value;
    setBookingInfo(prevState => ({
      ...prevState,
      reservation_id,
      sex: undefined,
      has_oxygen: undefined
    }));
    setLocaleBookingInfo(initialLocaleBookingInfo);
  }, [initialLocaleBookingInfo]);

  const handleSearchBooking = useCallback((value: string) => {
    if (value) {
      bookingSearcher({
        value,
        bookingStateSetter: setBookingInfo,
        modalStateSetter: setLocaleBookingInfo,
        departments,
        bookingInfo
      });
    } else {
      errorNotification(null, 'Заполните поле идентификатора');
    }
  }, [bookingInfo, departments]);

  const bookTypeRadios = useMemo(() => {
    const selectedDepartmentInfo = departments?.find(department => department?.id === bookingInfo?.department_id);

    return [
      {
        label: 'Муж.',
        value: 'male',
        style: { width: '20%' },
        disabled: !selectedDepartmentInfo?.count_male_free ||
          (localeBookingInfo.typeOfBookTransaction === 'withBooking' && localeBookingInfo.radioIdentification !== 'male')
      },
      {
        label: 'Муж. О2',
        value: 'male_o2',
        style: { width: '20%' },
        disabled: !selectedDepartmentInfo?.count_male_o2_free ||
          (localeBookingInfo.typeOfBookTransaction === 'withBooking' && localeBookingInfo.radioIdentification !== 'male_o2')
      },
      {
        label: 'Жен.',
        value: 'female',
        style: { width: '20%' },
        disabled: !selectedDepartmentInfo?.count_female_free ||
          (localeBookingInfo.typeOfBookTransaction === 'withBooking' && localeBookingInfo.radioIdentification !== 'female')
      },
      {
        label: 'Жен. О2',
        value: 'female_o2',
        style: { width: '20%' },
        disabled: !selectedDepartmentInfo?.count_female_o2_free ||
          (localeBookingInfo.typeOfBookTransaction === 'withBooking' && localeBookingInfo.radioIdentification !== 'female_o2')
      },
      {
        label: 'Реб.',
        value: 'children',
        style: { width: '20%' },
        disabled: !selectedDepartmentInfo?.count_children_free ||
          (localeBookingInfo.typeOfBookTransaction === 'withBooking' && localeBookingInfo.radioIdentification !== 'children')
      }
    ];
  }, [departments, localeBookingInfo.radioIdentification, localeBookingInfo.typeOfBookTransaction, bookingInfo.department_id]);

  useEffect(() => {
    const value = localeBookingInfo.radioIdentification;
    patientTypeRadioInputSwitcher({
      patientType: value,
      bookingStateSetter: setBookingInfo,
      modalStateSetter: setLocaleBookingInfo,
      typeSwitcherChanged: switcherTypes.main
    });
  }, [localeBookingInfo.radioIdentification]);

  const handleTypeBookChange = useCallback((event: RadioChangeEvent) => {
    const { value } = event.target;
    patientTypeRadioInputSwitcher({
      patientType: value,
      bookingStateSetter: setBookingInfo,
      modalStateSetter: setLocaleBookingInfo,
      typeSwitcherChanged: switcherTypes.main
    });
  }, []);

  const currentBookTransactionTypeRadios = useMemo(() => {
    return [
      { label: 'С номером бронирования', style: { width: '50%' }, value: 'withBooking' },
      { label: 'Без номера бронирования', style: { width: '50%' }, value: 'withoutBooking' }
    ];
  }, []);

  const localeNotification = useMemo(
    () => {
      const correctedType = !isChildHospitalizing && localeBookingInfo.localeNotification === LocalNotification.successWithoutPlaces
        ? LocalNotification.success
        : localeBookingInfo.localeNotification

      return (
        <LocaleNotificationSetter
          typeOfNotification={correctedType}
          hospitalName={bookingInfo.hospital_name}
        />
      );
    },
    [bookingInfo.hospital_name, isChildHospitalizing, localeBookingInfo.localeNotification]
  );

  /** Свитчер типа койки для опекуна в случае если госпитализируем ребёнка и опекуна */

  const guardianBunkTypeRadios = useMemo(() => {
    const selectedDepartmentInfo = departments?.find(department => department?.id === guardianBookingInfo.from_department_id);

    return [
      {
        label: 'Мужская',
        value: 'male',
        style: { width: '50%' },
        disabled: !selectedDepartmentInfo?.count_male_free
      },
      {
        label: 'Женская',
        value: 'female',
        style: { width: '50%' },
        disabled: !selectedDepartmentInfo?.count_female_free
      }
    ];
  }, [departments, guardianBookingInfo.from_department_id]);

  const guardianBunkSwitcherHandler = useCallback((event: RadioChangeEvent) => {
      guardianBunkRadioInputSwitcher({
        patientType: event.target.value,
        bookingStateSetter: setGuardianBookingInfo,
        modalStateSetter: setLocaleBookingInfo
      });
    },
    []
  );

  const GuardianBunkSwitcher = useMemo(
    () => {
      if (bookingInfo.sex === 'CHILDREN' && isGuardianHospitalizing && isChildHospitalizing && localeBookingInfo.typeOfBookTransaction === 'withBooking') return (
        <label className={styles.label}>
          Койка которая будет выделена опекуну:
          <Radio.Group
            style={{ width: '100%', textAlign: 'center' }}
            options={guardianBunkTypeRadios}
            onChange={guardianBunkSwitcherHandler}
            optionType='button'
            buttonStyle='solid'
            size='large'
            value={localeBookingInfo.guardianBunkTypeId}
          />
        </label>
      );
    },
    [bookingInfo.sex, isGuardianHospitalizing, isChildHospitalizing, localeBookingInfo.typeOfBookTransaction,
      guardianBunkTypeRadios, guardianBunkSwitcherHandler, localeBookingInfo.guardianBunkTypeId]
  );

  /** свитчер пола опекуна если выбрана госпитализация опекуна вместе с ребёнком */
  const guardianSexTypeRadios = useMemo(() => ([
      {
        label: 'Мужчина',
        value: 'male',
        style: { width: '50%' }
      },
      {
        label: 'Женщина',
        value: 'female',
        style: { width: '50%' }
      }
    ]),
    []
  );

  const guardianWithoutChildSexTypeRadios = useMemo(
    () => {
      const selectedDepartmentInfo = departments?.find(department => department?.id === bookingInfo?.department_id);

      return [
        {
          label: 'Мужчина',
          value: 'male',
          style: { width: '25%' },
          disabled: !selectedDepartmentInfo?.count_male_free,
        },
        {
          label: 'Муж. О2',
          value: 'male_o2',
          style: { width: '25%' },
          disabled: !selectedDepartmentInfo?.count_male_o2_free,
        },
        {
          label: 'Женщина',
          value: 'female',
          style: { width: '25%' },
          disabled: !selectedDepartmentInfo?.count_female_free,
        },
        {
          label: 'Жен. О2',
          value: 'female_o2',
          style: { width: '25%' },
          disabled: !selectedDepartmentInfo?.count_female_o2_free,
        }
      ];
    },
    [bookingInfo.department_id, departments]
  );

  const guardianSexSwitcherHandler = useCallback((event: RadioChangeEvent) => {
      guardianSexRadioInputSwitcher({
        patientType: event.target.value,
        bookingStateSetter: setGuardianBookingInfo,
        modalStateSetter: setLocaleBookingInfo
      });
    },
    []
  );

  const GuardianSexSwitcher = useMemo(
    () => {
      if (bookingInfo.sex === 'CHILDREN' && isGuardianHospitalizing && localeBookingInfo.typeOfBookTransaction === 'withBooking') return (
        <label className={styles.label}>  {isChildHospitalizing ? 'Пол опекуна:' : 'Пол госпитализируемого:'}
          <Radio.Group
            style={{ width: '100%', textAlign: 'center' }}
            options={isChildHospitalizing ? guardianSexTypeRadios : guardianWithoutChildSexTypeRadios}
            onChange={guardianSexSwitcherHandler}
            optionType='button'
            buttonStyle='solid'
            size='large'
            value={localeBookingInfo.guardianSexTypeId}
          />
        </label>
      );
    },
    [bookingInfo.sex, isGuardianHospitalizing, localeBookingInfo.typeOfBookTransaction, localeBookingInfo.guardianSexTypeId,
      isChildHospitalizing, guardianSexTypeRadios, guardianWithoutChildSexTypeRadios, guardianSexSwitcherHandler]
  );

  /** Селектор выбора из какого отдела взять койку */
  const handleSelectGuardianFromDepartment = useCallback((fromDepartmentId: number) => {
    setGuardianBookingInfo(prevState => ({
      ...prevState,
      from_department_id: fromDepartmentId,
      from_sex: undefined
    }));
    setLocaleBookingInfo(prevState => ({
      ...prevState,
      guardianSexTypeId: undefined,
      guardianBunkTypeId: undefined,
    }));
  }, []);

  const GuardiansHospitalisationBookDepartmentInfo = useMemo(
    () => {
      const selectedDepartmentInfo = departments?.find(department => department?.id === bookingInfo?.department_id);

      if ((isGuardianHospitalizing && isChildHospitalizing) && !selectedDepartmentInfo?.count_female_free
        && !selectedDepartmentInfo?.count_male_free && localeBookingInfo.typeOfBookTransaction === 'withBooking') {
        return (
          <>
            <label className={styles.label}>
              Из какого отделения взять койку:
              <Select className={styles.select}
                      placeholder={'Куда'} size={'large'}
                      value={guardianBookingInfo.from_department_id}
                      onSelect={handleSelectGuardianFromDepartment}
              >
                {hospitalOptions}
              </Select>
            </label>
          </>
        );
      }
    },
    [bookingInfo.department_id, departments, guardianBookingInfo.from_department_id, handleSelectGuardianFromDepartment,
      hospitalOptions, isChildHospitalizing, isGuardianHospitalizing, localeBookingInfo.typeOfBookTransaction]
  );

  /** свитчер госпитализации опекуна и ребёнка */
  const setChildHospitalizingHandle = useCallback(
    () => {
      setIsChildHospitalizing(!isChildHospitalizing);

      setGuardianBookingInfo((prevState) => ({
        ...prevState,
        to_sex: undefined,
        from_sex: undefined
      }));

      setLocaleBookingInfo((prevState) => ({
        ...prevState,
        guardianSexTypeId: undefined,
        guardianBunkTypeId: undefined
      }));
    },
    [isChildHospitalizing]
  );

  const setGuardianHospitalizingHandle = useCallback(
    () => {
      setGuardianHospitalizing(!isGuardianHospitalizing);

      setGuardianBookingInfo((prevState) => ({
        ...prevState,
        to_sex: undefined,
        from_sex: undefined,
        from_department_id: bookingInfo.department_id,
        reservation_id: bookingInfo.guardian_reservation_id
      }));

      setLocaleBookingInfo((prevState) => ({
        ...prevState,
        guardianSexTypeId: undefined,
        guardianBunkTypeId: undefined
      }));
    },
    [bookingInfo.department_id, bookingInfo.guardian_reservation_id, isGuardianHospitalizing]
  );

  const ChildGuardianHospitalisedSwitchers = useMemo(
    () => {
      if (localeBookingInfo.typeOfBookTransaction === 'withBooking' && bookingInfo.sex === 'CHILDREN') {
        return (
          <>
            <div className={styles.switcherWrapper}>
              Нужно ли госпитализировать ребёнка?
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={setChildHospitalizingHandle}
                size='default'
                checked={isChildHospitalizing}
              />
            </div>
            <div className={styles.switcherWrapper}>
              Нужно ли госпитализировать опекуна?
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={setGuardianHospitalizingHandle}
                size='default'
                checked={isGuardianHospitalizing}
              />
            </div>
          </>
        );
      }
    },
    [bookingInfo.sex, isChildHospitalizing, isGuardianHospitalizing, localeBookingInfo.typeOfBookTransaction, setChildHospitalizingHandle,
      setGuardianHospitalizingHandle]
  );

  const GuardianHospitalisationInfo = useMemo(
    () => (
      <>
        {ChildGuardianHospitalisedSwitchers}
        {GuardiansHospitalisationBookDepartmentInfo}
        {GuardianSexSwitcher}
        {GuardianBunkSwitcher}
      </>
    ),
    [ChildGuardianHospitalisedSwitchers, GuardianBunkSwitcher, GuardianSexSwitcher, GuardiansHospitalisationBookDepartmentInfo]
  );

  const HospitalisedInfo = useMemo(() => {
      if (bookingInfo.sex !== 'CHILDREN' || isChildHospitalizing || (!isChildHospitalizing && !isGuardianHospitalizing)) {
        let RadioButtons;

        if (localeBookingInfo.typeOfBookTransaction === 'withBooking') {
          RadioButtons = <Radio.Group
            style={{ width: '100%', textAlign: 'center' }}
            disabled={!bookingInfo?.department_id || !localeBookingInfo.localeNotification}
            options={bookTypeRadios}
            onChange={handleTypeBookChange}
            value={localeBookingInfo.radioIdentification}
            optionType='button'
            buttonStyle='solid'
            size='large'
          />;
        }
        if (localeBookingInfo.typeOfBookTransaction === 'withoutBooking') {
          RadioButtons = <Radio.Group
            style={{ width: '100%', textAlign: 'center' }}
            disabled={!bookingInfo?.department_id}
            options={bookTypeRadios}
            onChange={handleTypeBookChange}
            value={localeBookingInfo.radioIdentification}
            optionType='button'
            buttonStyle='solid'
            size='large'
          />;
        }

        return (
          <label className={styles.label}> Информация о госпитализируемом:
            {RadioButtons}
          </label>
        );
      }
    },
    [bookTypeRadios, bookingInfo?.department_id, bookingInfo.sex, handleTypeBookChange, isChildHospitalizing,
      isGuardianHospitalizing, localeBookingInfo.localeNotification, localeBookingInfo.radioIdentification,
      localeBookingInfo.typeOfBookTransaction]
  );

  return (
    <>
      <Modal
        width={700}
        className={styles.modalWindow}
        visible={true}
        destroyOnClose={true}
        centered
        title='Госпитализация'
        onOk={handleBookBunk}
        onCancel={handleCloseModal}
        footer={footerButtons}
      >
        <div className={styles.contentWrapper}>
          <label className={styles.label}> Тип размещения:
            <Radio.Group
              style={{ width: '100%', textAlign: 'center' }}
              options={currentBookTransactionTypeRadios}
              onChange={(e) => handleTypeBookTransactionChange(e.target.value)}
              optionType='button'
              buttonStyle='solid'
              size='large'
              value={localeBookingInfo.typeOfBookTransaction}
            />
          </label>
          <label className={styles.label}>
            Отделение госпиталя:
            <Select className={styles.select}
                    placeholder={'Куда'} size={'large'}
                    value={bookingInfo?.department_id}
                    onSelect={handleSelectHospitalDepartment}
            >
              {hospitalOptions}
            </Select>
          </label>
          {localeBookingInfo.typeOfBookTransaction === 'withBooking' &&
            <label className={styles.label}>
              Идентификатор бронирования:
              <Search placeholder='Введите идентификатор бронирования'
                      onSearch={handleSearchBooking}
                      enterButton size='large'
                      value={bookingInfo?.reservation_id}
                      onChange={handleChangeBookingDocument}
                      className={styles.integerInput}
              />
            </label>}
          {localeNotification}
          {HospitalisedInfo}
          {GuardianHospitalisationInfo}
        </div>
      </Modal>
    </>
  );
});