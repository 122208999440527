import React from "react";
import {Table, Tag} from 'antd';
import {TableBookingListProps} from "./model/TableBookingList.model";
import {BookingListItem} from "../../model/BookingListItem.model";
import {Button} from "../../../../Components/Button/Button";
import {ColumnsType} from "antd/es/table";
import moment from "moment-timezone";

export const TableBookingList = ({ bookingList, deleteBookingRecord } : TableBookingListProps) => {

    const sortedByDate = bookingList.sort((recordA, recordB)=>{
        return + new Date(recordB?.date_time ?? 0) -  + new Date(recordA?.date_time ?? 0)
    })

    const dateSorter = (dateA : string | undefined, dateB: string | undefined) : number => {
        if (dateA && dateB){
            if (moment(dateB).isAfter(moment(dateA))) return 1
            if (moment(dateB).isSame(moment(dateA))) return 0
            if (moment(dateB).isBefore(moment(dateA))) return -1
        } else {
            if (dateA) return -1;
            if (dateB) return 1;
            return 0
        }
        return 0;
    }

    const columns : ColumnsType<BookingListItem>= [
        {
            title: 'Идентификатор брони',
            dataIndex: 'reservation_id',
            key: 'reservation_id',
            sorter: (a, b) => +a.reservation_id - +b.reservation_id,
            sortDirections: ['descend', 'ascend'],
            width: 100,
            fixed: 'left',
        },
        {
            title: 'Дата и время бронирования',
            render: (record) => moment(record.date_time).format('DD.MM.YYYY HH:mm'),
            sorter: (a, b) => dateSorter(a.date_time,b.date_time),
            sortDirections: ['descend', 'ascend'],
            key: 'date_time',
            width: 100,
        },
        {
            title: 'Госпиталь',
            dataIndex: 'hospital_name',
            key: 'hospital_name',
            sorter: (a, b) => (a.hospital_name).localeCompare(b.hospital_name),
            width: 100,
        },
        {
            title: 'Пол',
            render: (record: BookingListItem) =>
              record.sex === "FEMALE"
              ?  <Tag color="pink">Ж</Tag>
              : record.sex === "CHILDREN"
                ? <Tag color="green">Д</Tag>
                : <Tag color="blue">М</Tag> ,
            key: 'sex',
            sorter: (a, b) => (a.sex).localeCompare(b.sex),
            width: 100,
        },
        {
            title: 'Кислород',
            render: (record: BookingListItem) => record.has_oxygen ? <Tag color="red">Да</Tag> : "Нет",
            sorter: (a, b) => +a.has_oxygen - +b.hospital_name,
            key: 'o2',
            width: 100,
        },
        {
            title: 'Снять бронь',
            render: (record: BookingListItem) => (<Button danger onClick={()=>deleteBookingRecord(record)} >Снять</Button>),
            key: 'o2',
            width: 100,
        },
    ];


    return(
        <Table
            columns={columns}
            dataSource={sortedByDate}
            pagination={false}
            sticky
            bordered
            size="middle"
        />
    )
}