import React, { useState } from 'react';
import { RolesTypes } from '../api/covidApi/model/User/RolesTypes';
import { IDName } from '../api/covidApi/model/IDName.model';

interface IProviderProps {
  children?: any;
}

interface AuthContextState {
  username: string;
  groups: RolesTypes[];
  departments: IDName[]
}

// noinspection JSUnusedLocalSymbols
export const authContextDefaultValue = {
  authState: { username: '', groups: [RolesTypes.none], departments: [] as IDName[] },
  setAuthState: (state: AuthContextState) => {
  } // noop default callback
};

export const AuthContext = React.createContext(authContextDefaultValue);

export const AuthProvider = (props: IProviderProps) => {
  const [authState, setAuthState] = useState(authContextDefaultValue.authState);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {props.children}
    </AuthContext.Provider>
  );
};