import React, { useCallback, useContext, useMemo, useState } from 'react';
import {Button, Modal, Select} from 'antd';
// @ts-ignore
import styles from './styles/ModalChangeDepartment.module.css';
import { AuthContext } from "../../../../providers/AuthProvider";
import { ModalChangeDepartmentProps } from './model/ModalChangeDepartment.model';
import { IDName } from '../../../../api/covidApi/model/IDName.model';

const {Option} = Select;

export const ModalChangeDepartment = React.memo((props:ModalChangeDepartmentProps) => {

    const {selectedDepartment, setSelectedDepartment,setModalVisible} = props;
    const [nextDepartment, setNextDepartment] = useState<IDName | null>(null)
    const {authState} = useContext(AuthContext);
    const currentUserDepartments = authState.departments

    const handleCloseModal = useCallback(() => {
        setModalVisible(false)
    }, [setModalVisible]);

    const handleChangeSelectedDepartment = useCallback(() => {
        setSelectedDepartment(nextDepartment);
        setModalVisible(false);
    }, [nextDepartment, setModalVisible, setSelectedDepartment])

    const handleSelectDepartment = useCallback((nextDepartmentId) => {
        const selectedNextDepartment = currentUserDepartments.find((el) => el.id === nextDepartmentId)
        if (selectedNextDepartment){
            setNextDepartment({
                id: selectedNextDepartment.id,
                name: selectedNextDepartment.name,
            });
        }
    }, [currentUserDepartments]);

    const DepartmentOptions = useMemo(() => {
        return currentUserDepartments?.map(department => <Option value={department.id}>{department.name}</Option>);
    }, [currentUserDepartments]);

    const footerButtons = useMemo(() => {
        return (
          <>
              <Button key="back" onClick={handleCloseModal}>
                  Отмена
              </Button>
              <Button type="primary" onClick={handleChangeSelectedDepartment}>
                  Сменить
              </Button>
          </>
        );
    }, [handleChangeSelectedDepartment, handleCloseModal]);

    return (
        <>
            <Modal
                width={600}
                className={styles.modalWindow}
                visible={true}
                destroyOnClose={true}
                centered
                title="Выписка"
                onOk={handleChangeSelectedDepartment}
                onCancel={handleCloseModal}
                footer={footerButtons}
            >
                <div className={styles.contentWrapper}>
                    <label className={styles.label}> Выберите отделение:
                        <Select className={styles.select} placeholder={'Отделение'} size={'large'}
                                defaultValue={selectedDepartment?.id}
                                onSelect={handleSelectDepartment}>
                            {DepartmentOptions}
                        </Select>
                    </label>
                </div>
            </Modal>
        </>
    );
});