import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input, Modal, Radio, Select, Switch } from 'antd';
// @ts-ignore
import styles from './styles/ModalBunkBooking.module.css';
import { covidApi } from '../../../../api/covidApi/covidApi';
import { ModalBunkBookingProps } from './model/ModalBunkBooking.model';
import { ILocalBookingState } from './model/LocalBookingState.model';
import { RadioChangeEvent } from 'antd/es/radio';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { Option } = Select;

export const ModalWindowsDispatcherPageBooking = React.memo((props: ModalBunkBookingProps) => {

  const { cleanupSelectedHospitalOnModalClose, setIsModalVisible, selectedHospitalId, hospitals, uploadHospitals } = props;

  const [isGuardianBooking, setIsGuardianBooking] = useState<boolean>(false);

  const [bookingInfo, setBookingInfo] = useState<ILocalBookingState>({
    sex: undefined,
    has_oxygen: undefined,
    hospital_id: selectedHospitalId,
    reservation_id: undefined,
    guardian_reservation_id: undefined,
  });

  useEffect(() => {
    return () => {
      if (selectedHospitalId) {
        cleanupSelectedHospitalOnModalClose();
      }
    };
  }, [cleanupSelectedHospitalOnModalClose, selectedHospitalId]);

  const allKeysDefined = useCallback((object: ILocalBookingState) => {
    const compareCondition = (key: string) => (
      (key.includes('reservation_id') && !object[key as keyof ILocalBookingState])
      || object[key as keyof ILocalBookingState] === undefined
    )

    return isGuardianBooking
      ? !Object.keys(object).some(key => compareCondition(key))
      : !Object.keys(object).some(key => key !== 'guardian_reservation_id' && compareCondition(key));
  }, [isGuardianBooking]);

  const handlerSetIsGuardianBooking = useCallback(() => {
    setIsGuardianBooking(!isGuardianBooking)
  }, [isGuardianBooking]);

  // закрытие модалки
  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const handleBookBunk = useCallback(() => {
    if (allKeysDefined(bookingInfo)) {
      const adapted = {
        reservation_id: bookingInfo.reservation_id ?? -1,
        hospital_id: bookingInfo.hospital_id ?? -1,
        sex: bookingInfo.sex ?? 'MALE',
        has_oxygen: bookingInfo.has_oxygen ?? false,
        ...(bookingInfo.guardian_reservation_id && { guardian_reservation_id: bookingInfo.guardian_reservation_id }),
      };
      covidApi.bookBunk(adapted)
        .then(response => {
            if (response) {
              uploadHospitals()
              setIsModalVisible(false);
            }
          }
        );
    }

  }, [allKeysDefined, bookingInfo, setIsModalVisible, uploadHospitals]);

  const footerButtons = useMemo(() => {
    const bookingDisabled = !allKeysDefined(bookingInfo);
    return (
      <>
        <Button key="back" onClick={handleCloseModal}>
          Отмена
        </Button>
        <Button type="primary" onClick={handleBookBunk} disabled={bookingDisabled}>
          Забронировать
        </Button>
      </>
    );
  }, [allKeysDefined, bookingInfo, handleBookBunk, handleCloseModal]);

  const hospitalOptions = useMemo(() => {
    return hospitals?.map(hospital => <Option value={hospital.id}>{hospital.name}</Option>);
  }, [hospitals]);

  const handleSelectHospital = (hospital_id: number) => {
    setBookingInfo(prevState => ({
      ...prevState,
      hospital_id
    }));
  };

  const handleChangeBookingDocument = useCallback((event: any) => {
    const reservation_id = event.target.value;
    setBookingInfo(prevState => ({
      ...prevState,
      reservation_id
    }));
  }, []);

  const handleChangeGuardianReservationId = useCallback((event: any) => {
    const guardian_reservation_id = event.target.value;
    setBookingInfo(prevState => ({
      ...prevState,
      guardian_reservation_id
    }));
  }, []);

  const bookTypeRadios = useMemo(() => {
    const selectedHospitalInfo = hospitals?.find(hospital => hospital.id === bookingInfo?.hospital_id);

    return [
      {
        label: 'Мужчина',
        style: { width: '20%' },
        value: 'male',
        disabled: !(selectedHospitalInfo && selectedHospitalInfo?.count_male_free > 0),
      },
      {
        label: 'Муж. О2',
        style: { width: '20%' },
        value: 'male_o2',
        disabled: !(selectedHospitalInfo && selectedHospitalInfo?.count_male_o2_free > 0),
      },
      {
        label: 'Женщина',
        style: { width: '20%' },
        value: 'female',
        disabled: !(selectedHospitalInfo && selectedHospitalInfo?.count_female_free > 0),
      },
      {
        label: 'Жен. О2',
        style: { width: '20%' },
        value: 'female_o2',
        disabled: !(selectedHospitalInfo && selectedHospitalInfo?.count_female_o2_free > 0),
      },
      {
        label: 'Ребёнок',
        style: { width: '20%' },
        value: 'children',
        disabled: !(selectedHospitalInfo && selectedHospitalInfo?.count_children_free > 0),
      }
    ];
  }, [bookingInfo.hospital_id, hospitals]);

  const handleTypeBookChange = useCallback((event: RadioChangeEvent) => {
    const { value } = event.target;
    switch (value) {
      case 'male':
        setBookingInfo(prevState => ({
          ...prevState,
          has_oxygen: false,
          sex: 'MALE'
        }));
        setIsGuardianBooking(false);
        break;
      case 'male_o2':
        setBookingInfo(prevState => ({
          ...prevState,
          has_oxygen: true,
          sex: 'MALE'
        }));
        setIsGuardianBooking(false);
        break;
      case 'female':
        setBookingInfo(prevState => ({
          ...prevState,
          has_oxygen: false,
          sex: 'FEMALE'
        }));
        setIsGuardianBooking(false);
        break;
      case 'female_o2':
        setBookingInfo(prevState => ({
          ...prevState,
          has_oxygen: true,
          sex: 'FEMALE'
        }));
        setIsGuardianBooking(false);
        break;
      case 'children':
        setBookingInfo(prevState => ({
          ...prevState,
          has_oxygen: false,
          sex: 'CHILDREN'
        }));
        break;
      default :
        break;
    }
  }, []);

  const guardianSwitcher = useMemo(() => (
  bookingInfo.sex === 'CHILDREN' ? (
      <div className={styles.switcherWrapper}>
        Опекун болен и требуется койка:
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={handlerSetIsGuardianBooking}
          size="default" />
      </div>
    ) : null
  ), [bookingInfo.sex, handlerSetIsGuardianBooking])

  const guardianReservationInput = useMemo(() => (
    isGuardianBooking
      ? <label className={styles.label}> Идентификатор брони опекуна ( Формат даты: ДД.ММ.ГГГГ ):
      <Input className={styles.integerInput} type={'number'} placeholder={'Идентификатор брони опекуна'}
             value={bookingInfo?.guardian_reservation_id}
             onChange={handleChangeGuardianReservationId} />
    </label>
      : null
  ), [bookingInfo, handleChangeGuardianReservationId, isGuardianBooking]);

  return (
    <>
      <Modal
        width={600}
        className={styles.modalWindow}
        visible={true}
        destroyOnClose={true}
        centered
        title="Бронирование"
        onOk={handleBookBunk}
        onCancel={handleCloseModal}
        footer={footerButtons}
      >
        <div className={styles.contentWrapper}>
          <label className={styles.label}> Выбор госпиталя:
            <Select className={styles.select} placeholder={'Куда'} size={'large'} value={bookingInfo?.hospital_id}
                    onSelect={handleSelectHospital}>
              {hospitalOptions}
            </Select>
          </label>
          <label className={styles.label}> Идентификатор брони ( Формат даты: ДД.ММ.ГГГГ ):
            <Input className={styles.integerInput} type={'number'} placeholder={'Идентификатор брони'}
                   value={bookingInfo?.reservation_id}
                   onChange={handleChangeBookingDocument} />
          </label>
          <label className={styles.label}> Информация о пациенте:
            <Radio.Group
              style={{ width: '100%', textAlign: 'center' }}
              disabled={!bookingInfo?.hospital_id}
              options={bookTypeRadios}
              onChange={handleTypeBookChange}
              optionType="button"
              buttonStyle="solid"
              size='large'
            />
          </label>
          {guardianSwitcher}
          {guardianReservationInput}
        </div>
      </Modal>
    </>
  );
});